import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const rootElement = document.getElementById("root");

if (rootElement) {
	const root = createRoot(rootElement);
	const render = (Component: React.FC) => {
		root.render(
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<React.StrictMode>
						<Component />
					</React.StrictMode>
				</PersistGate>
			</Provider>
		);
	};

	render(App);

	if (module.hot) {
		module.hot.accept("./App", () => {
			const NextApp = require("./App").default;
			render(NextApp);
		});
	}
} else {
	console.error("Root element not found");
}
