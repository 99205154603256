import React, { useCallback, useEffect, useState } from "react";
import { Form, message, Result } from "src/assets/imports/antdComponents";
import { IUserDetails, User } from "./models/interfaces";
import SubHeader from "./components/SubHeader";
import UserTable from "./components/UserTable";
import AddUserModal from "./components/AddUserModal";
import { DeleteUser, enableAccess, getUsers, saveUserDetails, updateUserDetails } from "./serviceHelper";
import { IResponse } from "../Studies/models/interfaces";
import CustSkeleton from "src/components/Skeleton";
import { transformPayload } from "./transformer";
import { getAdminDropdownValues } from "../Studies/serviceHelper";
import { setAdminDropDownMaster } from "./states/userManagementSlice";
import { useDispatch } from "react-redux";

const InitialDetails = { name: "", email: "", emp_id: "", profiles: [] };

const UserManagement: React.FC = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [searchText, setSearchText] = useState("");
	const [tableData, setTableData] = useState<User[]>();
	const [copiedData, setCopiedData] = useState<User[]>();
	const [toogle, setToggle] = useState(false);
	const [openUserModal, setOpenUserModal] = useState(false);
	const [userDetails, setUserDetails] = useState<IUserDetails>(InitialDetails);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [disabledFields, setDisableFields] = useState(false);

	async function fetchAdminDropdownValues() {
		try {
			const response: IResponse = await getAdminDropdownValues();
			const data = response?.data;
			if (data) {
				dispatch(setAdminDropDownMaster(data));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}
	const getListOfUsers = async () => {
		try {
			const response: IResponse = await getUsers();
			setTableData(response.data);
			setCopiedData(response?.data);
		} catch (error) {
			console.log("error", error);
			setError(true);
		} finally {
			setError(false);
			setLoading(false);
		}
	};

	useEffect(() => {
		getListOfUsers();
		fetchAdminDropdownValues();
	}, []);

	const successMsg = (message) => {
		messageApi.open({
			type: "success",
			content: message,
		});
	};

	const errorMsg = (message) => {
		messageApi.open({
			type: "error",
			content: message,
		});
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchText(value);
		if (value) {
			setTableData((preData) => copiedData.filter((item) => item.collaborator_name.toLowerCase().includes(value.toLowerCase()) || item.collaborator_id.includes(value)));
		} else {
			setTableData(copiedData);
		}
	};

	const handleEnableSelected = useCallback(
		(checked: boolean) => {
			// Enable Access for Selected User
		},
		[tableData]
	);

	const handleRowToggle = async (record: User) => {
		const index = tableData.findIndex((ele) => ele.collaborator_id === record.collaborator_id);
		const data = [...tableData];
		const rowdata = data[index];

		data[index] = { ...rowdata, enable_access: !rowdata.enable_access };
		setTableData(data);

		try {
			const payload: IUserDetails = transformPayload(record);
			const response: IResponse = await enableAccess({ ...payload, enable_access: !record.enable_access });
			successMsg(response.message || "User access enabled successfully!");
		} catch (error) {
			errorMsg("Something went wrong!");
			console.log("error", error);
		} finally {
			getListOfUsers();
		}
	};

	const handleUserModal = () => {
		setOpenUserModal(!openUserModal);
		setUserDetails(InitialDetails);
		setDisableFields(false);
	};

	const validateForm = useCallback(async () => {
		try {
			await form.validateFields();
			return true;
		} catch (err) {
			console.error("Form validation error:", err);
			return false;
		}
	}, [form]);

	const handleSaveUser = async () => {
		const isFormValid = await validateForm();
		if (isFormValid) {
			try {
				let response: IResponse;
				if (disabledFields) {
					response = await updateUserDetails({ ...userDetails, delete: false, enable_access: true });
				} else {
					response = await saveUserDetails({ ...userDetails, delete: false, enable_access: true });
				}
				successMsg(response.message || "User saved successfully!");
				handleUserModal();
				setUserDetails(InitialDetails);
			} catch (err) {
				errorMsg("Something went wrong!");
				console.log("error", err);
			} finally {
				getListOfUsers();
			}
		}
	};

	const handleDelete = async (record: User) => {
		try {
			const payload = {
				email: record.collaborator_email,
				name: record.collaborator_name,
				emp_id: record.collaborator_id,
				delete: true,
			};
			const response: IResponse = await DeleteUser(payload);
			successMsg(response.message || "User deleted successfully!");
		} catch (error) {
			errorMsg("Something went wrong!");
			console.log("error", error);
		} finally {
			getListOfUsers();
		}
	};

	const handleEdit = (record: User) => {
		const payload: IUserDetails = transformPayload(record);
		setUserDetails(payload);
		setDisableFields(true);
		setOpenUserModal(true);
	};

	if (error) {
		return <Result status="500" title="" subTitle="Sorry, something went wrong." />;
	}

	if (loading) {
		return (
			<div className="m-6">
				<CustSkeleton rows={0} />
				<CustSkeleton type="table" className="mt-2" />
			</div>
		);
	}

	return (
		<>
			{contextHolder}
			<AddUserModal
				form={form}
				userDetails={userDetails}
				setUserDetails={setUserDetails}
				isModalVisible={openUserModal}
				handleClose={handleUserModal}
				handleSave={handleSaveUser}
				disabledFields={disabledFields}
				tableData={tableData}
			/>
			<SubHeader
				searchText={searchText}
				handleSearch={handleSearch}
				selectedRowKeys={selectedRowKeys}
				handleOpenUserModal={handleUserModal}
				handleEnableSelected={handleEnableSelected}
				toggle={toogle}
				successMsg={successMsg}
				errorMsg={errorMsg}
			/>
			<UserTable
				data={tableData}
				selectedRowKeys={selectedRowKeys}
				onSelectChange={onSelectChange}
				handleRowToggle={handleRowToggle}
				handleDelete={handleDelete}
				handleEdit={handleEdit}
			/>
		</>
	);
};

export default UserManagement;
