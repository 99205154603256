import axiosInstance from "./axiosInstance";

class ApiService {
	async get<T>(url: string, params = {}): Promise<T | null> {
		try {
			const response = await axiosInstance.get<T>(url, { params });
			return response.data;
		} catch (err) {
			console.error("Error : ", err.message);
			throw err;
		}
	}

	async post<T>(url: string, data: any, config = {}): Promise<T | null> {
		try {
			const response = await axiosInstance.post<T>(url, data, config);
			return response.data;
		} catch (err) {
			console.error("Error : ", err.message);
			throw err;
		}
	}

	async put<T>(url: string, data: any, params = {}): Promise<T | null> {
		try {
			const response = await axiosInstance.put<T>(url, data, { params });
			return response.data;
		} catch (err) {
			console.error("Error : ", err.message);
			throw err;
		}
	}

	async delete<T>(url: string, params = {}): Promise<T | null> {
		try {
			const response = await axiosInstance.delete<T>(url, { params });
			return response.data;
		} catch (err) {
			console.error("Error : ", err.message);
			throw err;
		}
	}

	async downloadFile(url: string, params = {}): Promise<Blob | null> {
		try {
			const response = await axiosInstance.get(url, { params, responseType: "blob" });
			return response.data;
		} catch (err) {
			console.error("Error : ", err.message);
			throw err;
		}
	}
}

export const apiService = new ApiService();
