// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout .left-navbar {
	background-color: #ffffff !important;
	padding: 0;
	z-index: 900;
	border-right: 1px solid #a3aed040;
	box-shadow: 3px 0px 16px 0px #0000000f;
}

.menu-help {
	position: absolute !important;
	bottom: 0.5rem !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected:hover {
	background: none !important;
	border: none !important;
}

.left-navbar .ant-menu-item-selected .navicon {
	position: relative;
}

.left-navbar .ant-menu-item-selected .navicon::after {
	content: "";
	display: block;
	width: 100%;
	height: 2px; /* Adjust the thickness of the underline */
	background-color: #4cbb96;
	position: absolute;
	bottom: -8px;
	left: 0;
	border-radius: 2px;
}

.left-navbar .ant-menu-item {
	/* margin-top: 1rem; */
	margin-bottom: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/SideNavbar/style.css"],"names":[],"mappings":"AAAA;CACC,oCAAoC;CACpC,UAAU;CACV,YAAY;CACZ,iCAAiC;CACjC,sCAAsC;AACvC;;AAEA;CACC,6BAA6B;CAC7B,yBAAyB;AAC1B;;AAEA;;CAEC,2BAA2B;CAC3B,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,cAAc;CACd,WAAW;CACX,WAAW,EAAE,0CAA0C;CACvD,yBAAyB;CACzB,kBAAkB;CAClB,YAAY;CACZ,OAAO;CACP,kBAAkB;AACnB;;AAEA;CACC,sBAAsB;CACtB,qBAAqB;AACtB","sourcesContent":[".ant-layout .left-navbar {\n\tbackground-color: #ffffff !important;\n\tpadding: 0;\n\tz-index: 900;\n\tborder-right: 1px solid #a3aed040;\n\tbox-shadow: 3px 0px 16px 0px #0000000f;\n}\n\n.menu-help {\n\tposition: absolute !important;\n\tbottom: 0.5rem !important;\n}\n\n.ant-menu-item-selected,\n.ant-menu-item-selected:hover {\n\tbackground: none !important;\n\tborder: none !important;\n}\n\n.left-navbar .ant-menu-item-selected .navicon {\n\tposition: relative;\n}\n\n.left-navbar .ant-menu-item-selected .navicon::after {\n\tcontent: \"\";\n\tdisplay: block;\n\twidth: 100%;\n\theight: 2px; /* Adjust the thickness of the underline */\n\tbackground-color: #4cbb96;\n\tposition: absolute;\n\tbottom: -8px;\n\tleft: 0;\n\tborder-radius: 2px;\n}\n\n.left-navbar .ant-menu-item {\n\t/* margin-top: 1rem; */\n\tmargin-bottom: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
