import React, { useState, useCallback, useRef, useMemo, memo, useEffect } from "react";
import { ICollaboratorData, ICustModalProps, IResponse, userAccessType } from "../../models/interfaces";
import { Button, EditOutlined, EyeOutlined, Input, Modal, SearchOutlined, Select, Space, Table, InputRef, Avatar, Spin, message } from "src/assets/imports/antdComponents";
import { getInitials, transformCollaborators } from "../../transformer";
import { addCollaborators, getCollaborators } from "../../serviceHelper";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedStudy, updateStudyCollaboratorData } from "../../states/studiesSlice";
import { RootState } from "src/store";

const accessOptions = [
	{
		value: userAccessType.edit,
		label: (
			<span className="flex items-center">
				<span className="mr-1">Edit</span>
				<EditOutlined className="ml-auto text-lg" />
			</span>
		),
	},
	{
		value: userAccessType.view,
		label: (
			<span className="flex items-center">
				<span className="mr-1">View</span>
				<EyeOutlined className="ml-auto text-lg" />
			</span>
		),
	},
];

const modalStyles = {
	header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4.5rem", padding: "1.25rem 1.5rem", margin: 0 },
	body: { padding: "1.5rem", height: "35rem" },
	mask: { backdropFilter: "blur(5px)" },
	footer: { padding: "1rem 1.5rem", margin: 0 },
	content: { padding: 0, margin: 0 },
};

const useDebounce = (value: string, delay: number) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

const AddCollaboratorModal: React.FC<ICustModalProps> = memo((props) => {
	const dispatch = useDispatch();
	const [selectedAccess, setSelectedAccess] = useState<userAccessType>(userAccessType.edit);
	const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([]);
	const [userAccessList, setUserAccessList] = useState<ICollaboratorData[]>(props.payload);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [searchedColumn, setSearchedColumn] = useState("");
	const [collaboratorOptions, setCollaboratorOptions] = useState<any[]>([]);
	const [fetching, setFetching] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const debouncedSearchValue = useDebounce(searchValue, 300);

	const searchInput = useRef<InputRef>(null);
	const metaDetails = useSelector((state: RootState) => state.studiesSlice.metaDetails);
	const userId = metaDetails?.id;

	useEffect(() => {
		setUserAccessList(props.payload);
	}, [props.payload]);

	useEffect(() => {
		// Check for changes whenever userAccessList is updated
		const isChanged = JSON.stringify(userAccessList) !== JSON.stringify(props.payload);
		setHasChanges(isChanged);
	}, [userAccessList, props.payload]);

	useEffect(() => {
		if (debouncedSearchValue) {
			fetchCollaborators(debouncedSearchValue);
		}
	}, [debouncedSearchValue]);

	async function fetchCollaborators(search: string) {
		setFetching(true);
		try {
			const response: any = await getCollaborators(search);
			if (response?.data) {
				const options = transformCollaborators(response.data);
				setCollaboratorOptions(options);
			}
		} catch (error) {
			console.error("Error fetching collaborator data:", error);
		} finally {
			setFetching(false);
		}
	}

	const handleAddUsers = useCallback(
		(collaboratorIds: string[]) => {
			// Generate new user access list based on collaboratorIds
			const newUserAccessList = collaboratorIds.map((collaboratorId) => {
				const tempCollab = [...userAccessList];
				collaboratorOptions.forEach((el) => {
					tempCollab.push({
						collaborator_id: el.value,
						collaborator_name: el.label,
						collaborator_email: el.email,
						user_access_type: el.user_access_type,
					});
				});

				const user = tempCollab.find((user) => user.collaborator_id === collaboratorId);

				// If this is the current user, maintain their existing access type
				if (collaboratorId === userId && selectedAccess === userAccessType.view) {
					message.warning("You cannot modify your access type.");
					const currentUser = userAccessList.find((u) => u.collaborator_id === userId);
					return {
						collaborator_id: collaboratorId,
						collaborator_name: user ? user.collaborator_name : "",
						collaborator_email: user ? user.collaborator_email : "",
						user_access_type: currentUser ? currentUser.user_access_type : selectedAccess,
					};
				}

				return {
					collaborator_id: collaboratorId,
					collaborator_name: user ? user.collaborator_name : "",
					collaborator_email: user ? user.collaborator_email : "",
					user_access_type: selectedAccess,
				};
			});

			setUserAccessList((prevUserAccessList) => {
				const updatedUserAccessList = [...prevUserAccessList];
				newUserAccessList.forEach((newUser) => {
					const existingIndex = updatedUserAccessList.findIndex((user) => user.collaborator_id === newUser.collaborator_id);
					if (existingIndex > -1) {
						// If this is the current user, don't update their access type
						if (newUser.collaborator_id !== userId) {
							updatedUserAccessList[existingIndex] = newUser;
						}
					} else {
						updatedUserAccessList.push(newUser);
					}
				});
				return updatedUserAccessList;
			});
		},
		[selectedAccess, collaboratorOptions, userAccessList, userId]
	);

	const handleRemoveSelectedUsers = useCallback(() => {
		const updatedUserAccessList = userAccessList.filter((user) => {
			if (selectedRowKeys.includes(user.collaborator_id)) {
				if (user.collaborator_id === userId) {
					message.warning("You cannot remove yourself from the collaborators list.");
					return true;
				}
				return false;
			}
			return true;
		});

		setUserAccessList(updatedUserAccessList);
		setSelectedRowKeys([]);
		setSelectedCollaborators([]);
	}, [selectedRowKeys, userAccessList, userId]);

	const handleSave = useCallback(async () => {
		if (!hasChanges) {
			props.handleCancel();
			return;
		}

		setLoading(true);
		const study_id = props.studyId;
		const collab_details = userAccessList;
		const addCollabObj = {
			study_id: study_id,
			collab_details: collab_details,
		};

		try {
			const response: IResponse = await addCollaborators(addCollabObj);
			if (response) {
				message.success(response.message);
				dispatch(updateStudyCollaboratorData({ study_id, collab_details }));
				const data = {
					study_collaborators: collab_details,
				};
				dispatch(updateSelectedStudy(data));
				props.handleCancel();
			}
		} catch (error) {
			console.error("Error:", error);
			message.error("An unexpected error occurred");
		} finally {
			setLoading(false);
		}
	}, [props.studyId, props.handleCancel, userAccessList, hasChanges]);

	const handleSearch = useCallback((selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		setSearchedColumn(dataIndex);
	}, []);

	const handleReset = useCallback((clearFilters: () => void) => {
		clearFilters();
	}, []);

	const getColumnSearchProps = useCallback(
		(dataIndex: keyof ICollaboratorData) => ({
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
				<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
					<Input
						ref={searchInput}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						style={{ marginBottom: 6, display: "block" }}
					/>
					<Space>
						<Button type="primary" onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small">
							Search
						</Button>
						<Button size="small" onClick={() => clearFilters && handleReset(clearFilters)}>
							Reset
						</Button>
						<Button
							type="link"
							size="small"
							onClick={() => {
								confirm({ closeDropdown: false });
								setSearchedColumn(dataIndex);
							}}>
							Filter
						</Button>
					</Space>
				</div>
			),
			filterIcon: () => <SearchOutlined />,
			onFilter: (value: string, record: ICollaboratorData) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
			onFilterDropdownOpenChange: (visible: boolean) => {
				if (visible) {
					setTimeout(() => searchInput.current?.select(), 100);
				}
			},
			render: (text: string) => (searchedColumn === dataIndex ? text : text),
		}),
		[handleSearch, handleReset, searchedColumn]
	);

	const columns = useMemo(
		() => [
			{
				title: "Collaborators",
				dataIndex: "collaborator_name",
				key: "collaborator_name",
				...getColumnSearchProps("collaborator_name"),
				render: (_: any, record: ICollaboratorData) => (
					<Space align="center">
						<Avatar size="small">{getInitials(record.collaborator_name)}</Avatar>
						{record.collaborator_name}
						<span className="text-base-1 tertiary-color">{record.collaborator_email}</span>
					</Space>
				),
			},
			{
				title: "Access",
				dataIndex: "user_access_type",
				key: "user_access_type",
				// filters: [
				// 	{
				// 		text: "Edit",
				// 		value: userAccessType.edit,
				// 	},
				// 	{
				// 		text: "View",
				// 		value: userAccessType.view,
				// 	},
				// ],
				// onFilter: (value: string, record: ICollaboratorData) => record.user_access_type === value,
				render: (access: userAccessType) => (
					<span className="flex items-center justify-center">
						{access === userAccessType.edit ? <EditOutlined className="text-lg" /> : <EyeOutlined className="text-lg" />}
					</span>
				),
				width: "15%",
			},
		],
		[getColumnSearchProps]
	);

	const rowSelection = useMemo(
		() => ({
			selectedRowKeys,
			onChange: (keys: React.Key[], selectedRows: ICollaboratorData[]) => {
				const filteredKeys = keys.filter((key) => key !== userId);
				setSelectedRowKeys(filteredKeys);
			},
			getCheckboxProps: (record: ICollaboratorData) => ({
				disabled: record.collaborator_id === userId,
			}),
		}),
		[selectedRowKeys, userId]
	);

	return (
		<Modal
			title="Add Collaborators"
			open={props.isModalOpen}
			onOk={handleSave}
			onCancel={props.handleCancel}
			width={500}
			centered
			styles={modalStyles}
			footer={[
				<Button key="remove" type="text" danger className="absolute left-5" onClick={handleRemoveSelectedUsers} disabled={!selectedRowKeys.length}>
					{userAccessList.length > 0 && "Remove"}
				</Button>,
				<Button key="back" type="text" onClick={props.handleCancel}>
					Cancel
				</Button>,
				hasChanges && (
					<Button key="submit" type="primary" loading={loading} onClick={handleSave}>
						Save
					</Button>
				),
			]}>
			<div className="flex flex-col space-y-4">
				<div className="flex space-x-4">
					<Space.Compact block>
						<Select
							mode="multiple"
							className="w-full"
							maxTagCount="responsive"
							value={selectedCollaborators}
							placeholder="Select Collaborators"
							onChange={(values) => {
								setSelectedCollaborators(values);
								handleAddUsers(values);
							}}
							onDeselect={(value) => {
								setSelectedCollaborators((prev) => prev.filter((id) => id !== value));
								handleRemoveSelectedUsers();
							}}
							options={collaboratorOptions}
							onSearch={(value) => setSearchValue(value)}
							filterOption={false}
							notFoundContent={fetching ? <Spin size="small" /> : null}
							optionRender={(option) => (
								<Space align="center">
									<Avatar size="small">{getInitials(option.data.label)}</Avatar>
									{option.data.label}
									<span className="text-base-1 tertiary-color">{option.data.email}</span>
								</Space>
							)}
						/>
						{/* <Select
							className="w-1/5"
							value={selectedAccess}
							onChange={(value: userAccessType) => {
								setSelectedAccess(value);
								setSelectedCollaborators([]);
							}}
							options={accessOptions}
						/> */}
					</Space.Compact>
				</div>
				{userAccessList.length > 0 && (
					<Table size="small" rowSelection={rowSelection} dataSource={userAccessList} columns={columns} pagination={false} rowKey="collaborator_id" scroll={{ y: 310 }} />
				)}
			</div>
		</Modal>
	);
});

export default AddCollaboratorModal;
