import React, { useState, useEffect, useCallback, Suspense } from "react";
import { ISubHeaderProps } from "./interface";
import { Avatar, PlusCircleFilled, Tag, Tooltip } from "src/assets/imports/antdComponents";
import { getInitials } from "src/pages/Studies/transformer";
import TagsModal from "src/pages/Studies/components/TagsModal";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { userAccessType } from "src/pages/Studies/models/interfaces";
import AddCollaboratorModal from "src/pages/Studies/components/AddCollaborator";
import "./style.css";

const SubHeader: React.FC<ISubHeaderProps> = ({ title, subtitle, type = "default", tags = [], buttons = [], onTagSelect, collaborators, updatedBy, createdBy }) => {
	const [activeTag, setActiveTag] = useState<string>("All");
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [tagList, setTagList] = useState<string[]>();
	const [fromModal, setFromModal] = useState<boolean>(false);
	const [isAddCollabModalOpen, setIsAddCollabModalOpen] = useState(false);
	const [collabModalKey, setCollabModalKey] = useState(0);

	const isStudies = title === "Studies";
	const visibleTagCount = isStudies ? 6 : 2;
	const remainingTags = tagList?.length - visibleTagCount;

	const selectedStudy = useSelector((state: RootState) => state.studiesSlice.selectedStudy);
	const hasAccess = selectedStudy?.access_type === userAccessType.edit;
	const studyId = selectedStudy?.study_id;

	useEffect(() => {
		if (type === "button" && tags.length > 0 && !tags.includes("All")) {
			tags = ["All", ...tags];
		}

		let updatedTags = tags;
		if (fromModal) {
			const remainingTags = updatedTags.filter((tag) => tag !== "All" && tag !== activeTag);
			if (activeTag === "All") {
				updatedTags = [activeTag, ...remainingTags];
			} else {
				updatedTags = ["All", activeTag, ...remainingTags];
			}

			if (updatedTags.includes(activeTag)) {
				updatedTags = updatedTags.filter((tag) => tag !== activeTag);
				if (updatedTags.length >= 5) {
					updatedTags.splice(5, 0, activeTag);
				} else {
					updatedTags.push(activeTag);
				}
			}
		}

		if (JSON.stringify(updatedTags) !== JSON.stringify(tagList)) {
			setTagList(updatedTags);
		}
	}, [tags, fromModal, activeTag, type, tagList]);

	const handleTagClick = useCallback(
		(tag: string, isModalClicked: boolean) => {
			setFromModal(isModalClicked);
			setActiveTag(tag);
			onTagSelect(tag);
		},
		[onTagSelect]
	);

	const showAddCollabModal = () => {
		setCollabModalKey((prevKey) => prevKey + 1);
		setIsAddCollabModalOpen(true);
	};

	const handleOk = () => {
		setIsAddCollabModalOpen(false);
	};

	const handleCancel = () => {
		setIsAddCollabModalOpen(false);
	};

	return (
		<div className="subHeader-nav flex items-center justify-between p-1 px-5 w-full">
			<TagsModal topTags={tagList} handleTagClick={handleTagClick} activeTag={activeTag} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} type={type} />
			<div className="flex items-center gap-4">
				<span className="text-2xl font-normal">{title}</span>
				{subtitle || isStudies ? <span className="border-l-2 border-[#112E6540] h-8"></span> : null}
				{subtitle && <span className="text-[1.16666667rem] font-normal secondary-color word-spacing-3">{subtitle}</span>}
				<div className="flex items-center">
					{tagList?.slice(0, visibleTagCount).map((tag, index) => (
						<Tag
							key={`${tag}-${index}`}
							className={`${isStudies ? "subHeader-tag text-base-1" : "study-tag"} ${activeTag === tag ? "active" : ""}`}
							onClick={isStudies ? () => handleTagClick(tag, false) : undefined}>
							{tag}
						</Tag>
					))}
					{remainingTags > 0 &&
						(isStudies ? (
							<Tag onClick={() => setIsModalVisible(true)} className="subHeader-tag text-base-1 cursor-pointer">
								+ {remainingTags} more
							</Tag>
						) : (
							<Tooltip
								title={
									<div>
										{tagList?.slice(visibleTagCount).map((tag, index) => (
											<Tag key={`${tag}-${index}`} className="study-tag">
												{tag}
											</Tag>
										))}
									</div>
								}>
								<Tag className="study-tag mr-0">+ {remainingTags} more</Tag>
							</Tooltip>
						))}
				</div>
				{type === "default" && collaborators?.length > 0 && (
					<div className="flex items-center -space-x-2">
						<Avatar.Group size="small" className="mix-blend-normal -z-0" max={{ count: 2 }}>
							{collaborators?.map((collaborator) => (
								<Tooltip key={collaborator.collaborator_id} title={collaborator.collaborator_name}>
									<Avatar key={collaborator.collaborator_id}>{getInitials(collaborator.collaborator_name)}</Avatar>
								</Tooltip>
							))}
						</Avatar.Group>
						<PlusCircleFilled
							className={`mix-blend-normal text-[20px] z-0 ${hasAccess ? "plus-icon" : "plus-icon-disabled"}`}
							onClick={() => {
								if (hasAccess) showAddCollabModal();
							}}
						/>
					</div>
				)}
			</div>
			<div className="flex items-center space-x-2 gap-3">
				{buttons.map((button, index) => (
					<span key={index}>{button}</span>
				))}
			</div>
			{updatedBy && (
				<div className="flex items-center space-x-3 gap-3">
					<div className="text-[x-small] secondary-color font-normal opacity-90">{updatedBy}</div>
				</div>
			)}
			<Suspense fallback={<></>}>
				{isAddCollabModalOpen && (
					<AddCollaboratorModal
						authorUser={createdBy}
						fullAccessUsers={collaborators}
						isModalOpen={isAddCollabModalOpen}
						handleOk={handleOk}
						handleCancel={handleCancel}
						key={collabModalKey}
						studyId={studyId}
					/>
				)}
			</Suspense>
		</div>
	);
};

export default React.memo(SubHeader);
