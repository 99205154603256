import { lazy } from "react";
import UserManagement from "src/pages/UserManagement";

const StudyPage = lazy(() => import("../pages/Studies"));
const InsightPage = lazy(() => import("../pages/Insights"));
const ManageStudies = lazy(() => import("../pages/ManageStudies"));

export const menuItems = [
	{
		key: "studies",
		icon: "studies",
		label: "Studies",
		path: "/studies",
		component: StudyPage,
	},
	{
		key: "insights",
		icon: "insights",
		label: "Insights",
		path: "/insights",
		component: InsightPage,
	},
	{
		key: "manageStudies",
		icon: "manageStudies",
		label: "Manage Studies",
		path: "/manage-studies",
		component: ManageStudies,
	},
	{
		key: "help",
		icon: "help",
		label: "Help",
		path: null,
		component: null,
	},
];

export const adminMenuItems = [
	{
		key: "userManagement",
		icon: "userManagement",
		label: "User Management",
		path: "/user-management",
		component: UserManagement,
	},
	{
		key: "help",
		icon: "help",
		label: "Help",
		path: null,
		component: null,
	},
];
