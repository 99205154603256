import { apiService } from "src/services";
import { IProfile, IUserDetails, User } from "../models/interfaces";

export const saveUserDetails = async (userDetails: IUserDetails) => {
	return await apiService.post("/admin/user", userDetails);
};

export const saveProfileDetails = async (profileDetails: IProfile) => {
	return await apiService.post("/admin/profile", profileDetails);
};

export const updateUserDetails = async (userDetails: IUserDetails) => {
	return await apiService.put("/admin/user", userDetails);
};

export const DeleteUsers = async (userIdArr: string) => {
	return await apiService.delete(`/admin/user`, userIdArr);
};
export const DeleteUser = async (userDetails: IUserDetails) => {
	return await apiService.delete(`/admin/user`, userDetails);
};
export const enableAccess = async (userDetails: IUserDetails) => {
	return await apiService.put(`/admin/enable_access`, userDetails);
};

export const getUsers = async () => {
	return await apiService.get("/admin/users");
};
