import React from "react";
import Authen from "src/assets/img/Authentication.gif";

const AuthScreen = () => {
	return (
		<div className="flex flex-col justify-center items-center h-screen w-screen bg-[#F2F6FA]">
			<div className="text-center">
				<div className="inline-block">
					<div className="relative">
						<img src={Authen} alt="Authen" />
					</div>
				</div>
				<p className="text-[1.33333333rem] text-[#63646B]">Authenticating...</p>
			</div>
		</div>
	);
};

export default AuthScreen;
