import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fileTypes, ICollaboratorData, IDropdownMaster, IMetaDetails, ISelectedStudy, IStudyData, userAccessType } from "../models/interfaces";
import { ITag } from "src/components/Searchbar/interface";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

interface StudiesState {
	studiesData: IStudyData[];
	collaboratorData: ICollaboratorData[];
	dropdownMaster: IDropdownMaster[];
	searchText: string;
	selectedCollaborators: ITag[];
	metaDetails: IMetaDetails;
	userProfile: string;
	selectedStudy: ISelectedStudy;
}

const initialState: StudiesState = {
	studiesData: [],
	collaboratorData: [],
	dropdownMaster: [],
	searchText: "",
	selectedCollaborators: [],
	metaDetails: {
		config: {
			is_report_available: {
				home: true,
				manage_study: false,
				add_study: true,
				ask_kural: true,
			},
		},
		id: "",
		name: "",
		firstName: "",
		lastName: "",
		isAdmin: false,
		roles: [],
		profiles: [],
		idm_application_name: "",
		user_email: "",
	},
	userProfile: "",
	selectedStudy: {
		study_id: null,
		study_name: null,
		study_tags: [],
		study_collaborators: [],
		insights_id: null,
		access_type: null,
		created_by: null,
	},
};

const studiesSlice = createSlice({
	name: "studies",
	initialState,
	reducers: {
		setAllStudiesData(state, action: PayloadAction<IStudyData[]>) {
			state.studiesData = action.payload;
		},
		setAllCollaboratorsData(state, action: PayloadAction<ICollaboratorData[]>) {
			state.collaboratorData = action.payload;
		},
		setDropdownMaster(state, action: PayloadAction<IDropdownMaster[]>) {
			state.dropdownMaster = action.payload;
		},
		setSearchText(state, action: PayloadAction<string>) {
			state.searchText = action.payload;
		},
		setSelectedCollaborators(state, action: PayloadAction<ITag[]>) {
			state.selectedCollaborators = action.payload;
		},
		updateStudyCollaboratorData(state, action: PayloadAction<{ collab_details: any; study_id: string }>) {
			const { study_id, collab_details } = action.payload;
			const study = state.studiesData.find((s) => s.study_id === study_id);
			study.collaborators = collab_details;
		},
		updateFileCount: (state, action: PayloadAction<{ study_id: string; study_type: string; count: number }>) => {
			const { study_id, study_type, count } = action.payload;
			const study = state.studiesData?.find((s) => s.study_id === study_id);
			if (study_type === fileTypes.transcript) study.count_of_transcript = count;
			if (study_type === fileTypes.report) study.count_of_report = count;
		},
		setMetaDetails: (state, action: PayloadAction<IMetaDetails>) => {
			state.metaDetails = {
				...state.metaDetails,
				...action.payload,
				config: action.payload?.config ?? state.metaDetails.config,
			};
		},
		setUserProfile: (state, action: PayloadAction<string>) => {
			state.userProfile = action.payload;
		},
		setSelectedStudy(state, action: PayloadAction<ISelectedStudy>) {
			state.selectedStudy = action.payload;
		},
		updateSelectedStudy(state, action: PayloadAction<any>) {
			state.selectedStudy = {
				...state.selectedStudy,
				...action.payload,
			};
		},
		// You can add more reducers here if needed
	},
});

const persistConfig = {
	key: "studies",
	storage,
	whitelist: ["userProfile", "selectedStudy"], // Only these fields will be persisted
};

// Create a persisted reducer for the studiesSlice
const persistedStudiesReducer = persistReducer(persistConfig, studiesSlice.reducer);

export const {
	setAllCollaboratorsData,
	setAllStudiesData,
	setDropdownMaster,
	setMetaDetails,
	setSearchText,
	setSelectedCollaborators,
	setSelectedStudy,
	setUserProfile,
	updateFileCount,
	updateSelectedStudy,
	updateStudyCollaboratorData,
} = studiesSlice.actions;

export default persistedStudiesReducer;
