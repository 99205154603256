import React, { useEffect, useState } from "react";
import { Button, Modal, Tag } from "src/assets/imports/antdComponents";

const modalStyles = {
	header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4.5rem", padding: "1.25rem 1.5rem", margin: 0 },
	body: { padding: "1.5rem" },
	mask: { backdropFilter: "blur(5px)" },
	footer: { padding: "1rem 1.5rem", margin: 0 },
	content: { padding: 0, margin: 0 },
};

const TagsModal = ({ topTags, activeTag, handleTagClick, isModalVisible, setIsModalVisible, type }) => {
	const [tempSelectedTags, setTempSelectedTags] = useState(activeTag);

	useEffect(() => {
		setTempSelectedTags(activeTag);
	}, [activeTag]);

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleApply = () => {
		handleTagClick(tempSelectedTags, true);
		setIsModalVisible(false);
	};

	const handleTagSelect = (tag) => {
		setTempSelectedTags(tag);
	};

	return (
		<Modal
			title="Select Tag"
			open={isModalVisible}
			onCancel={handleCancel}
			styles={modalStyles}
			footer={[
				<Button key="cancel" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="apply" type="primary" onClick={handleApply}>
					Apply
				</Button>,
			]}>
			<div className="flex flex-wrap gap-2 justify-start items-center ">
				{topTags?.map((tag, index) => (
					<Tag
						key={`${tag}-${index}`}
						className={`${type === "default" ? "study-tag text-blue-900 mr-3" : "subHeader-tag text-blue-900 mr-3"} ${tempSelectedTags === tag ? "active" : ""}`}
						onClick={() => handleTagSelect(tag)}>
						{tag}
					</Tag>
				))}
			</div>
		</Modal>
	);
};

export default TagsModal;
