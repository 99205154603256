import dayjs from "dayjs";
import { fileTypes, IAddStudyData, ICollaboratorData, IStudyData, ITableProps, ReportAvailabilityKey } from "../models/interfaces";

export const transformFormValues = (values: any): IAddStudyData => {
	const { fieldwork_date, ...rest } = values;
	let start_date: string | undefined;
	let end_date: string | undefined;

	if (Array.isArray(fieldwork_date) && fieldwork_date.length === 2) {
		start_date = fieldwork_date[0] ? dayjs(fieldwork_date[0]).format("YYYY-MM-DD HH:mm:ss") : undefined;
		end_date = fieldwork_date[1] ? dayjs(fieldwork_date[1]).format("YYYY-MM-DD HH:mm:ss") : undefined;
	}

	return {
		...rest,
		...(start_date && { start_date }),
		...(end_date && { end_date }),
	};
};

export const getTopTags = (studies: IStudyData[]): string[] => {
	// Step 1: Extract and count occurrences of each tag in one pass
	const tagCounts = (studies || []).reduce<Record<string, number>>((acc, study) => {
		(study?.custom_tags || []).forEach((tag) => {
			if (tag) {
				acc[tag] = (acc[tag] || 0) + 1;
			}
		});
		return acc;
	}, {});

	// Step 2: Sort tags by occurrences and select the top 5
	const topTags = Object.entries(tagCounts)
		.sort(([, countA], [, countB]) => countB - countA)
		.map(([tag]) => tag);

	// Step 3: Add 'All' tag at the beginning
	return ["All", ...topTags];
};

export const getInitials = (name: string | null): string => {
	if (!name) return "";
	return name
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase())
		.slice(0, 2)
		.join("");
};

export function transformCustomTags(tags: { label: string; value: string }[] | null): { label: string; value: string }[] {
	return (tags || []).map((tag) => ({
		...tag,
		value: tag.label || "",
	}));
}

export function transformCollaborators(collaborators: ICollaboratorData[]) {
	return collaborators.map((collaborator) => ({
		value: collaborator.collaborator_id ?? "",
		label: collaborator.collaborator_name ?? "",
		email: collaborator.collaborator_email ?? "",
		user_access_type: collaborator.user_access_type ?? "Read Access",
	}));
}

export const checkAccessType = (type) => {
	if (type === "Read Access") {
		return "View";
	} else if (type === "Full Access") {
		return "Edit";
	}
	return "Access Required";
};

export function transformPayload(input: any[]): ITableProps[] {
	return input.map((item) => {
		const baseOutput: ITableProps = {
			id: item._id,
			uploaded_files: item.filename,
			uploaded_by: item.uploaded_by.full_name,
			uploaded_date: item.upload_dt,
			status: item.processing_status,
			type: item.document_type,
			status_log: item.display_on_ui,
			file_id: item.file_id,
		};

		if (item.transcripts_metadata) {
			Object.entries(item.transcripts_metadata).forEach(([key, value]) => {
				const formattedKey = key.toLowerCase().replace(/\s+/g, "_");
				baseOutput[formattedKey] = typeof value === "string" ? value : JSON.stringify(value);
			});
		}

		return baseOutput;
	});
}

export const dayjsDate = (date) => {
	if (!date) return "";
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const utcDate = date.slice(0, -3) + "Z";
	const updated = new Date(utcDate).toLocaleString("en-US", {
		timeZone: userTimeZone,
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
		timeZoneName: "short",
	});
	return dayjs(updated).format("MM/DD/YY");
};

export const dayjsTime = (date) => {
	if (!date) return "";
	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const utcDate = date.slice(0, -3) + "Z";
	const updated = new Date(utcDate).toLocaleString("en-US", {
		timeZone: userTimeZone,
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
		timeZoneName: "short",
	});
	return dayjs(updated).format("hh:mm A");
};

export const transformNameWithDateTime = (uploadedBy, uploadDate) => {
	if (!uploadedBy && !uploadDate) return ""; //"No upload information";
	const formattedDate = uploadDate ? `${dayjsDate(uploadDate)} at ${dayjsTime(uploadDate)}` : "";
	const formattedText = `By ${uploadedBy || "Unknown User"} on ${formattedDate}`;
	return formattedText;
};

export const truncateFileName = (entityName: string, length: number = 20) => {
	return entityName.length > length ? entityName.substring(0, length) : entityName;
};

export const generateFileName = (fileType: string, entityName: string, ques_id?: string | number) => {
	const truncatedFileName = truncateFileName(entityName?.split(".")[0]);
	switch (fileType) {
		case fileTypes.discussion_guide:
			return `${truncatedFileName} - QualAI Kural Discussion Guide.docx`;
		case fileTypes.transcript:
			return `${truncatedFileName} - QualAI Kural Transcript.docx`;
		case fileTypes.all:
			return `${truncatedFileName} - QualAI Kural Study Data.zip`;
		case fileTypes.metadata:
			return `${truncatedFileName} - QualAI Kural Metadata Template.xlsx`;
		case fileTypes.allInsights:
			return `${truncatedFileName} - QualAI Kural Insights Deep Dive.xlsx`;
		case fileTypes.insightsQuestion:
			return `${truncatedFileName} - QualAI Kural Insights - Question ${ques_id}.xlsx`;
		default:
			return `${truncatedFileName} - QualAI Kural File`;
	}
};

export const isReportAvailable = (key: ReportAvailabilityKey, metaDetails): boolean => {
	switch (key) {
		case ReportAvailabilityKey.HOME:
			return metaDetails?.config?.is_report_available?.home ?? false;
		case ReportAvailabilityKey.MANAGE_STUDY:
			return metaDetails?.config?.is_report_available?.manage_study ?? false;
		case ReportAvailabilityKey.ADD_STUDY:
			return metaDetails?.config?.is_report_available?.add_study ?? false;
		case ReportAvailabilityKey.ASK_KURAL:
			return metaDetails?.config?.is_report_available?.ask_kural ?? false;
		default:
			return false; // Default to false if the key is not found
	}
};
