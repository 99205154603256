import React, { useCallback, useState, Suspense } from "react";
import Kural from "public/icon/Kural.svg";
import Logo from "public/icon/Vector.svg";
import KuralButton from "public/icon/AskKuralButton.svg";
import Searchbar from "../../Searchbar";
import { Avatar, Button, Divider, Header, Popover, Select, Space } from "src/assets/imports/antdComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { setAllStudiesData, setDropdownMaster, setSearchText, setSelectedCollaborators, setSelectedStudy, setUserProfile } from "src/pages/Studies/states/studiesSlice";
import { ILevel1, ITag } from "src/components/Searchbar/interface";
import { getInitials } from "src/pages/Studies/transformer";
import { useNavigate } from "react-router-dom";
import { roleAccessType } from "src/pages/Studies/models/interfaces";
import "./style.css";

const AskKuralModal = React.lazy(() => import("pages/AskKural"));

const TopNavbar: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isAskKuralModalOpen, setIsAskKuralModalOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const studiesData = useSelector((state: RootState) => state.studiesSlice.studiesData);
	const metaDetails = useSelector((state: RootState) => state.studiesSlice.metaDetails);
	const userProfile = useSelector((state: RootState) => state.studiesSlice.userProfile);
	const userFullName = `${metaDetails?.firstName} ${metaDetails?.lastName}`;

	const redirectToHome = useCallback(() => {
		navigate("/");
	}, []);

	const onCollaboratorSearch = (collaborators: ITag[]) => {
		dispatch(setSelectedCollaborators(collaborators));
	};

	const onClickSearch = useCallback((value: string) => {
		// console.log("onClickSearch value:", value);
		dispatch(setSearchText(value));
	}, []);

	const onChange = useCallback((value: string) => {
		// console.log("onChange value:", value);
	}, []);

	const showModal = () => {
		setIsAskKuralModalOpen(true);
	};

	const handleOk = () => {
		setIsAskKuralModalOpen(false);
	};

	const handleCancel = () => {
		setIsAskKuralModalOpen(false);
	};

	//TODO move to transformer later
	// Function to transform studiesPayload to studyPayload
	function transformToStudyPayload(studies): ILevel1[] {
		return studies?.map((study) => ({
			level1Id: study.study_id,
			level1Name: study.study_name,
			lstLevel2s: study.custom_tags?.map((tag) => ({ level2Id: tag, level2Name: tag })),
			collaborators: study.collaborators,
			insightsAvailable: study.insights_available,
			access_type: study.user_access_type,
			insights_id: study.active_insights_id,
			created_by: study.version_history.created_by,
		}));
	}

	// Function to extract unique collaborators
	function extractUniqueCollaborators(studies) {
		const collaboratorMap = new Map();

		studies?.forEach((study) => {
			study?.collaborators?.forEach((collaborator) => {
				if (!collaboratorMap.has(collaborator.collaborator_id)) {
					collaboratorMap.set(collaborator.collaborator_id, {
						collaboratorId: collaborator.collaborator_id,
						collaboratorName: collaborator.collaborator_name,
						collaboratorEmail: collaborator.collaborator_email,
					});
				}
			});
		});

		return Array.from(collaboratorMap.values());
	}

	const handleProfileChange = useCallback((value: string) => {
		dispatch(setUserProfile(value));
		dispatch(setSelectedStudy(null));
		dispatch(setAllStudiesData([]));
		dispatch(setDropdownMaster([]));
		setOpen(false);
		redirectToHome();
	}, []);

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const handleLogOut = () => {
		window.location.href = process.env.REACT_APP_SIGNOUT_URL;
	};

	const popContent = (
		<div className="w-60">
			<div className="flex items-center">
				<Avatar size="small" className="navbar-icon flex items-center justify-center mr-4">
					{getInitials(userFullName)}
				</Avatar>
				<div className="flex flex-col">
					<span className="text-[1.166666667rem] font-medium secondary-color">{userFullName}</span>
					<span className="text-[x-small] secondary-color">{metaDetails?.user_email}</span>
				</div>
			</div>
			<Divider className="my-4" />
			<div className="flex flex-col">
				<label className="text-[x-small] tertiary-color mb-1">User Profile:</label>
				<Select
					key="user-profile"
					defaultValue={userProfile}
					className="py-[0.2rem] custom-dropdown text-base-1 custom-select"
					popupClassName="custom-dropdown"
					onChange={handleProfileChange}
					options={metaDetails?.profiles?.map((profile) => ({ value: profile, label: profile }))}
				/>
			</div>
			<Divider className="my-4" />
			<div className="flex justify-start">
				<span className="text-lg font-medium tertiary-color cursor-pointer flex items-center">
					<div onClick={() => handleLogOut()} className="flex items-center cursor-pointer">
						<i className="material-symbols-outlined primary-color text-2xl mr-1">logout</i>
						<span className="text-[1.166666667rem] primary-color font-medium">Log Out</span>
					</div>
				</span>
			</div>
		</div>
	);

	return (
		<Header className="top-navbar flex justify-between items-center">
			<div className="top-navbar-left flex items-center w-4/5 gap-16">
				{/* <Logo className="w-32 item-center text-start cursor-pointer" onClick={redirectToHome} /> */}
				<Kural className="w-32 item-center text-start cursor-pointer" onClick={redirectToHome} />
				{userProfile !== roleAccessType.Admin && (
					<Searchbar
						payload={transformToStudyPayload(studiesData || [])}
						collaborators={extractUniqueCollaborators(studiesData || [])}
						onCollaboratorSearch={onCollaboratorSearch}
						onChange={onChange}
						onClickSearch={onClickSearch}
					/>
				)}
			</div>
			<div className="top-navbar-right flex items-center w-1/5 justify-end">
				{userProfile !== roleAccessType.Admin && (
					<Button type="primary" className="flex items-center ask-kural-btn mr-5" icon={<KuralButton />} onClick={showModal}>
						Ask Kural
					</Button>
				)}
				<Space size="large" className="flex items-center space-x-0.5">
					{/* <Badge count={10} className="notification-badge">
						<BellOutlined className="navbar-icon text-3xl secondary-color" />
					</Badge> */}
					{/* TODO: For Demo */}
					<Popover content={popContent} placement="bottomRight" trigger="click" open={open} onOpenChange={handleOpenChange}>
						<Avatar className="navbar-icon cursor-pointer" style={{ backgroundColor: "#63646b", color: "white", fontWeight: 600 }}>
							{getInitials(userFullName)}
						</Avatar>
					</Popover>
				</Space>
			</div>
			<Suspense fallback={<></>}>{isAskKuralModalOpen && <AskKuralModal isModalOpen={isAskKuralModalOpen} handleOk={handleOk} handleCancel={handleCancel} />}</Suspense>
		</Header>
	);
};

export default TopNavbar;
