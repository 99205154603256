import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { StyleProvider } from "@ant-design/cssinjs";
import { theme } from "./config/themeConfig";
import TopNavbar from "./components/Navbar/TopNavbar";
import SideNavbar from "./components/Navbar/SideNavbar";
import RoutesConfig from "./config/routesConfig";
import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "antd/es/layout";
import ConfigProvider from "antd/es/config-provider";
import "antd/dist/reset.css";
import "./assets/styles/theme.css";
import "./assets/styles/antdTheme.css";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudies, getDropdownValues, getMetaDetails } from "./pages/Studies/serviceHelper";
import { transformCustomTags } from "./pages/Studies/transformer";
import { setAllStudiesData, setDropdownMaster, setMetaDetails, setUserProfile } from "./pages/Studies/states/studiesSlice";
import { RootState, store } from "./store";
import { injectStore } from "./services/axiosInstance";
import AuthScreen from "./components/AutenticatingComp";
import { Result } from "./assets/imports/antdComponents";

const { Content } = Layout;
injectStore(store);

const AppContent: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const studiesData = useSelector((state: RootState) => state.studiesSlice.studiesData);
	const dropdownMaster = useSelector((state: RootState) => state.studiesSlice.dropdownMaster);
	const userProfile = useSelector((state: RootState) => state.studiesSlice.userProfile);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setError] = useState(false);

	async function fetchMetaDetails() {
		try {
			const response: any = await getMetaDetails();
			if (response?.data) {
				const loggedInUser = response?.data?.logged_in_user;
				if (!loggedInUser || loggedInUser?.profiles.length === 0) {
					setIsAuthenticated(false);
				} else {
					dispatch(setMetaDetails(loggedInUser));
					const profile = userProfile ? userProfile : loggedInUser?.profiles[0];
					dispatch(setUserProfile(profile));
					setIsAuthenticated(true);
				}
			}
		} catch (error: any) {
			console.error("Error:", error);
			if (error.response && (error.response.status === 401 || error.response.status === 403)) {
				setIsAuthenticated(false);
			} else {
				setError(true);
			}
		}
	}

	async function fetchDropdownValues() {
		try {
			const response: any = await getDropdownValues();
			if (response?.data) {
				const transformedData = { ...response.data, custom_tags: transformCustomTags(response.data.custom_tags) };
				dispatch(setDropdownMaster(transformedData));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}

	async function fetchAllStudies() {
		try {
			const response: any = await getAllStudies();
			if (response?.data) {
				dispatch(setAllStudiesData(response.data));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}

	useEffect(() => {
		fetchMetaDetails();
	}, []);

	useEffect(() => {
		if (isAuthenticated && dropdownMaster?.length === 0) {
			fetchDropdownValues();
		}
	}, [isAuthenticated, dropdownMaster]);

	useEffect(() => {
		if (isAuthenticated && userProfile && location.pathname !== "/" && location.pathname !== "/studies" && studiesData.length === 0) {
			fetchAllStudies();
		}
	}, [isAuthenticated, userProfile, location.pathname, studiesData.length]);

	if (!isAuthenticated) {
		return <AuthScreen />;
	}
	if (error) {
		return <Result status="500" title="" subTitle="Sorry, something went wrong." />;
	}

	return (
		<ErrorBoundary resetKeys={[location.pathname]}>
			<Layout>
				<TopNavbar />
				<Layout>
					<SideNavbar />
					<Layout>
						<Content style={{ height: "calc(100vh - 4.8rem)" }}>
							<RoutesConfig />
						</Content>
					</Layout>
				</Layout>
			</Layout>
		</ErrorBoundary>
	);
};

const App: React.FC = () => {
	return (
		<ConfigProvider theme={theme}>
			<StyleProvider>
				<Router>
					<AppContent />
				</Router>
			</StyleProvider>
		</ConfigProvider>
	);
};

export default App;
