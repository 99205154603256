import React, { Suspense, useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuItems, adminMenuItems } from "src/config/menuConfig";
import { Sider, Menu, MenuProps, Modal } from "src/assets/imports/antdComponents";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmationData, setUnsavedChanges } from "src/pages/ManageStudies/states/slice/unsavedChangesSlice";
import { setActiveSection, updateSections } from "src/pages/ManageStudies/states/slice/manageDescriptionGuideSlice";
import "./style.css";

import Home from "public/icon/home.svg";
import HomeFilled from "public/icon/home_fill.svg";
import Insights from "public/icon/insights.svg";
import InsightsFilled from "public/icon/insights_fill.svg";
import ManageStudies from "public/icon/manageStudies.svg";
import ManageStudiesFilled from "public/icon/manageStudies_fill.svg";
import HelpIcon from "public/icon/help.svg";
import { confirmationStatusType, confirmationTypes, roleAccessType } from "src/pages/Studies/models/interfaces";
import ConfirmationDialog from "src/components/ConfirmationDialog";
const Help = React.lazy(() => import("src/pages/Help"));
const SideNavbar: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
	const hasUnsavedChanges = useSelector((state: RootState) => state.unsavedChangesSlice.hasUnsavedChanges);
	const initialConfirmationData = { status: "", editType: "", visible: false, name: "", redirectFrom: "" };
	const confirmationData = useSelector((state: RootState) => state.unsavedChangesSlice.confirmationData);
	const userProfile = useSelector((state: RootState) => state.studiesSlice.userProfile);
	const [key, setKey] = useState("");

	const selectedKey = useMemo(() => {
		return userProfile === roleAccessType.Admin
			? menuItems.find((item) => item.path === location.pathname)?.key || "user-management"
			: menuItems.find((item) => item.path === location.pathname)?.key || "studies";
	}, [location.pathname, userProfile]);

	const icons: { [key: string]: React.ReactNode } = useMemo(
		() => ({
			studies: <i className="navicon">{selectedKey === "studies" ? <HomeFilled /> : <Home />}</i>,
			insights: <i className="navicon">{selectedKey === "insights" ? <InsightsFilled /> : <Insights />}</i>,
			manageStudies: <i className="navicon">{selectedKey === "manageStudies" ? <ManageStudiesFilled /> : <ManageStudies />}</i>,
			help: (
				<i className="navicon">
					<HelpIcon />
				</i>
			),
		}),
		[selectedKey]
	);

	const adminIcons: { [key: string]: React.ReactNode } = useMemo(
		() => ({
			userManagement: <i className="navicon">{selectedKey === "user-management" ? <HomeFilled /> : <Home />}</i>,
			help: (
				<i className="navicon">
					<HelpIcon />
				</i>
			),
		}),
		[selectedKey]
	);

	const handleClick = useCallback(
		(key: string) => {
			if (key === "help") {
				setIsHelpModalOpen(true);
			} else if (hasUnsavedChanges) {
				dispatch(
					setConfirmationData({
						...confirmationData,
						redirectFrom: confirmationTypes.type3,
						status: confirmationStatusType.warning,
						visible: true,
					})
				);
				setKey(key);
			} else {
				dispatch(setUnsavedChanges(false));
			}
		},
		[hasUnsavedChanges]
	);

	const handleCancel = () => {
		setIsHelpModalOpen(false);
	};

	const onConfirm = () => {
		dispatch(setUnsavedChanges(false));
		dispatch(setActiveSection(null));
		dispatch(updateSections([]));
		navigate(key);
		dispatch(setConfirmationData(initialConfirmationData));
	};

	const onCancel = () => {
		dispatch(setConfirmationData({ ...confirmationData, visible: false }));
	};

	const menuItemsWithIcons: MenuProps["items"] = useMemo(() => {
		const menu = userProfile === roleAccessType.Admin ? adminMenuItems : menuItems;
		const items = menu.map((item) => ({
			key: item.key,
			icon: userProfile === roleAccessType.Admin ? adminIcons[item.key] : icons[item.key],
			label: hasUnsavedChanges ? <span>{item.label}</span> : <Link to={item.path}>{item.label}</Link>,
			className: `flex justify-center items-center py-8 ${item.key === "help" ? "menu-help" : item.key === "settings" ? "menu-settings" : ""}`,
			onClick: () => handleClick(item.key === "help" ? item.key : item.path),
			title: item.label,
		}));
		return items;
	}, [userProfile, icons, hasUnsavedChanges]);

	return (
		<>
			{confirmationData?.redirectFrom === confirmationTypes.type3 && <ConfirmationDialog confirmationData={confirmationData} onCancel={onCancel} onConfirm={onConfirm} />}
			<Sider width={48} className="left-navbar">
				<Menu mode="inline" selectedKeys={[selectedKey]} className="h-full" items={menuItemsWithIcons} />
			</Sider>
			<Suspense fallback={<></>}>{isHelpModalOpen && <Help key="help-modal" isModalOpen={isHelpModalOpen} handleOk={handleCancel} handleCancel={handleCancel} />}</Suspense>
		</>
	);
};

export default SideNavbar;
