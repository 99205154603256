// src/config/RoutesConfig.tsx
import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { menuItems } from "./menuConfig";
import Loading from "../components/LoadingComp";

const RoutesConfig: React.FC = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				<Route path="/" element={<Navigate to={menuItems[0].path} />} />
				{menuItems.map((item) => (
					<Route key={item.key} path={item.path} element={<item.component />} />
				))}
			</Routes>
		</Suspense>
	);
};

export default RoutesConfig;
