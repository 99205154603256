import React, { useCallback } from "react";
import { IErrorFallbackProps } from "./interface";
import { Button, Result } from "src/assets/imports/antdComponents";

const ErrorFallback: React.FC<IErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
	console.log("ErrorFallback", error);

	const redirectToHome = useCallback(() => {
		window.location.href = "/";  // This will navigate to the home page and reload the app
	}, []);

	const reload = useCallback(() => {
		window.location.reload();  // This will reload the current page
	}, []);

	return (
		<div role="alert" className="flex w-full h-full justify-center item-center">
			<Result
				status="500"
				title="We are sorry! Something went wrong while accessing this page"
				subTitle="We request you to try after some time or contact support for help"
				extra={
					<>
						<Button type="primary" className="mr-5" onClick={reload}>
							Refresh
						</Button>
						<Button onClick={redirectToHome}>Go to Home</Button>
					</>
				}
			/>
		</div>
	);
};

export default ErrorFallback;
