import React from "react";
import { Card, Skeleton, Table } from "src/assets/imports/antdComponents";
import { ICustSkeletonProps } from "./interface";

const CustSkeleton: React.FC<ICustSkeletonProps> = ({ type, rows, style, className }) => {
	const commonProps = {
		style,
		className,
	};

	switch (type) {
		case "card":
			return <Card {...commonProps} loading />;
		case "form":
			return <Skeleton {...commonProps} active title={false} paragraph={{ rows: rows, width: "100%" }} />;
		case "table":
			return <Table {...commonProps} loading={true} />;
		default:
			return <Skeleton {...commonProps} active paragraph={{ rows: rows, width: "100%" }} />;
	}
};

export default CustSkeleton;
