import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Hint } from "react-autocomplete-hint";
import Fuse from "fuse.js";
import { ICollaborator, ILevel1, ILevel2, ISearchProps, ITag } from "./interface";
import { Avatar, Divider, Tag, Tooltip } from "src/assets/imports/antdComponents";
import useOutsideClick from "./useOutsideClick";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchText, setSelectedCollaborators, setSelectedStudy } from "src/pages/Studies/states/studiesSlice";
import { getInitials } from "src/pages/Studies/transformer";
import { ISelectedStudy } from "src/pages/Studies/models/interfaces";
import "./style.css";

const Searchbar: React.FC<ISearchProps> = ({ payload, collaborators, onChange, onCollaboratorSearch, onClickSearch }) => {
	const [input, setInput] = useState<string>("");
	const [suggestions, setSuggestions] = useState<ILevel1[]>([]);
	const [collaboratorSuggestions, setCollaboratorSuggestions] = useState<ICollaborator[]>([]);
	const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
	const [recentSearches, setRecentSearches] = useState<ITag[]>([]);
	const [hints, setHints] = useState<string[]>([]);
	const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const fuseOptions = useMemo(
		() => ({
			keys: ["level1Name", "lstLevel2s.level2Name"],
			includeScore: true,
			threshold: 0.3,
		}),
		[]
	);

	const collaboratorFuseOptions = useMemo(
		() => ({
			keys: ["collaboratorName"],
			includeScore: true,
			threshold: 0.3,
		}),
		[]
	);

	const fuse = useMemo(() => new Fuse(payload, fuseOptions), [payload, fuseOptions]);
	const collaboratorFuse = useMemo(() => new Fuse(collaborators || [], collaboratorFuseOptions), [collaborators, collaboratorFuseOptions]);

	const getSuggestions = useCallback(
		(query: string): ILevel1[] => {
			const parts = query
				.toLowerCase()
				.split(";")
				.map((part) => part.trim());
			let matches: ILevel1[] = [];

			const resultSets = parts.map((part) => fuse.search(part).map(({ item }) => item));

			if (resultSets.length > 0) {
				matches = resultSets.reduce((acc, resultSet) => {
					if (acc.length === 0) return resultSet;
					return acc.filter((item) => resultSet.some((resultItem) => item.level1Name === resultItem.level1Name));
				}, []);
			}

			return matches.slice(0, 5);
		},
		[fuse]
	);

	const getCollaboratorSuggestions = useCallback(
		(query: string) => {
			const parts = query
				.toLowerCase()
				.split(";")
				.map((part) => part.trim());
			let matches: ICollaborator[] = [];

			const resultSets = parts.map((part) => collaboratorFuse.search(part).map(({ item }) => item));

			if (resultSets.length > 0) {
				matches = resultSets.reduce((acc, resultSet) => {
					if (acc.length === 0) return resultSet;
					return acc.filter((item) => resultSet.some((resultItem) => item.collaboratorName === resultItem.collaboratorName));
				}, []);
			}

			// debugger;
			// Filter out duplicates
			const uniqueMatches = matches.filter((item, index, self) => index === self.findIndex((t) => t.collaboratorName === item.collaboratorName));

			return uniqueMatches.slice(0, 5);
		},
		[collaboratorFuse]
	);

	useEffect(() => {
		if (input) {
			const matches = getSuggestions(input);
			const collaboratorMatches = getCollaboratorSuggestions(input);
			setSuggestions(matches);
			setCollaboratorSuggestions(collaboratorMatches);
			setIsDropdownVisible(true);
		} else {
			setSuggestions(payload.slice(0, 5));
			setCollaboratorSuggestions(collaborators ? collaborators.slice(0, 5) : []);
			setIsDropdownVisible(false);
		}
	}, [input, getSuggestions, getCollaboratorSuggestions]);

	useEffect(() => {
		const hintList: string[] = [];
		payload?.forEach((level1) => {
			hintList.push(level1.level1Name);
			level1.lstLevel2s?.forEach((level2) => {
				hintList.push(level2.level2Name);
			});
		});
		if (collaborators) {
			collaborators?.forEach((collaborator) => {
				hintList.push(collaborator.collaboratorName);
			});
		}
		setHints(hintList);
	}, [payload, collaborators]);

	const handleCollaboratorClick = (collaborator: ICollaborator) => {
		const collaboratorTag: ITag = {
			collaboratorId: collaborator.collaboratorId,
			collaborator: collaborator.collaboratorName,
		};
		setSelectedTags((prevTags) => {
			const existingCollaborator = prevTags.find((tag) => tag.collaboratorId === collaborator.collaboratorId);
			if (!existingCollaborator) {
				const newTags = [...prevTags, collaboratorTag];
				onCollaboratorSearch && onCollaboratorSearch(newTags);
				onClickSearch && onClickSearch(input);
				return newTags;
			}
			return prevTags;
		});
		// Remove the last segment of the input and append the selected collaborator as a tag
		const parts = input.split(";");
		parts.pop(); // Remove the last segment
		const newInput = parts.join(";").trim();
		setInput(newInput ? `${newInput}; ` : ""); // Keep the rest of the input intact
		setCollaboratorSuggestions([]);
		setIsDropdownVisible(false);
	};

	const filterRecentSearches = useCallback(
		(query: string): ITag[] => {
			const parts = query
				.toLowerCase()
				.split(";")
				.map((part) => part.trim());
			return recentSearches.filter((search) => parts.every((part) => `${search.lvl1}`.toLowerCase().includes(part)));
		},
		[recentSearches]
	);

	const removeTag = (id: string | undefined) => {
		const newTags = selectedTags.filter((tag) => tag.lvl1Id !== id && tag.collaboratorId !== id);
		setSelectedTags(newTags);
		onCollaboratorSearch && onCollaboratorSearch(newTags);
		onClickSearch && onClickSearch(input);
	};

	const handleRecentSearches = (selectedTag: ITag) => {
		setRecentSearches((prevRecentSearches) => [...prevRecentSearches, selectedTag]);
	};

	const onClickSearchIcon = (event: React.MouseEvent<HTMLDivElement>) => {
		onClickSearch && onClickSearch(input);
		setIsDropdownVisible(false); // Collapse the dropdown after search
		inputRef.current?.blur(); // Make the input lose focus
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			onClickSearch && onClickSearch(input);
			setIsDropdownVisible(false);
			inputRef.current?.blur();
		} else if (event.key === "Backspace" && input === "" && selectedTags.length > 0) {
			// Remove the last tag when backspace is pressed and input is empty
			const lastTag = selectedTags[selectedTags.length - 1];
			removeTag(lastTag.lvl1Id || lastTag.collaboratorId);
		}
	};

	const highlightMatch = useCallback((text: string, query: string): JSX.Element => {
		if (!query) {
			return <>{text}</>;
		}

		const parts = query
			.split(";")
			.map((part) => part.trim())
			.filter((part) => part !== "");
		const regex = new RegExp(`(${parts.join("|")})`, "gi");

		const segments = text.split(regex);

		return (
			<>
				{segments.map((segment, index) =>
					regex.test(segment) ? (
						<span key={index} className="highlight">
							{segment}
						</span>
					) : (
						<span key={index}>{segment}</span>
					)
				)}
			</>
		);
	}, []);

	const removeRecentSearch = (index: number) => {
		const newRecentSearches = [...recentSearches];
		newRecentSearches.splice(index, 1);
		setRecentSearches(newRecentSearches);
	};

	const clearAllTags = () => {
		setSelectedTags([]);
	};

	const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>, suggestion: ILevel1, action: string) => {
		e.stopPropagation(); // Prevent triggering the suggestion click
		if (action === "insights") {
			handleViewInsights(suggestion);
		} else if (action === "manage") {
			handleManageStudies(suggestion);
		}
		resetSearchBox();
	};

	const resetSearchBox = () => {
		setInput("");
		setSelectedTags([]);
		setSuggestions([]);
		setCollaboratorSuggestions([]);
		setIsDropdownVisible(false);
	};

	const handleManageStudies = (suggestion: ILevel1) => {
		const data: ISelectedStudy = {
			study_id: suggestion.level1Id,
			study_name: suggestion.level1Name,
			study_tags: suggestion.lstLevel2s?.map((tag) => tag.level2Name),
			study_collaborators: suggestion.collaborators,
			insights_id: suggestion.insights_id,
			access_type: suggestion.access_type,
			created_by: suggestion.created_by,
		};
		dispatch(setSelectedStudy(data));
		navigate("/manage-studies");
	};

	const handleViewInsights = (suggestion: ILevel1) => {
		const data: ISelectedStudy = {
			study_id: suggestion.level1Id,
			study_name: suggestion.level1Name,
			study_tags: suggestion.lstLevel2s?.map((tag) => tag.level2Name),
			study_collaborators: suggestion.collaborators,
			insights_id: suggestion.insights_id,
			access_type: suggestion.access_type,
			created_by: suggestion.created_by,
		};
		dispatch(setSelectedStudy(data));
		navigate("/insights");
	};

	const handleInputFocus = () => {
		if (input) {
			const matches = getSuggestions(input);
			const collaboratorMatches = getCollaboratorSuggestions(input);
			setSuggestions(matches);
			setCollaboratorSuggestions(collaboratorMatches);
		} else {
			const initialSuggestions = payload.slice(0, 5);

			let initialCollaborators = collaborators ? collaborators.slice(0, 5) : [];

			// Filter out duplicates
			initialCollaborators = initialCollaborators.filter((item, index, self) => index === self.findIndex((t) => t.collaboratorName === item.collaboratorName));

			setSuggestions(initialSuggestions);
			setCollaboratorSuggestions(initialCollaborators);
		}
		setIsDropdownVisible(true);
	};

	const searchContainerRef = useOutsideClick(() => {
		setIsDropdownVisible(false);
	});

	const renderTags = (level2Tags: ILevel2[], query: string): JSX.Element => {
		const matchingTags = level2Tags?.filter((tag) => tag.level2Name.toLowerCase().includes(query.toLowerCase()));
		const nonMatchingTags = level2Tags?.filter((tag) => !tag.level2Name.toLowerCase().includes(query.toLowerCase()));
		const tagsToDisplay = [...(matchingTags || []), ...(nonMatchingTags || [])].slice(0, 3);
		const tagsToHide = [...(matchingTags || []), ...(nonMatchingTags || [])].slice(3);

		return (
			<>
				{tagsToDisplay?.map((tag, index) => (
					<Tag key={index} className="study-tag secondary-color">
						{highlightMatch(tag.level2Name, query)}
					</Tag>
				))}
				{tagsToHide.length > 0 && (
					<Tooltip
						title={
							<div>
								{tagsToHide.map((tag, index) => (
									<Tag key={index} className="study-tag secondary-color">
										{highlightMatch(tag.level2Name, query)}
									</Tag>
								))}
							</div>
						}
						placement="top"
						overlayClassName="custom-tooltip">
						<Tag className="study-tag secondary-color">+ {tagsToHide.length} more</Tag>
					</Tooltip>
				)}
			</>
		);
	};

	// Add a useEffect hook to reset the search box on route change
	useEffect(() => {
		resetSearchBox();
		dispatch(setSelectedCollaborators([]));
		dispatch(setSearchText());
	}, [location.pathname]);

	return (
		<div className="search-container" ref={searchContainerRef}>
			<div className="flex items-center input-container">
				{selectedTags.map((tag, index) => (
					<Tag
						key={tag.lvl1Id ? `lvl1-${tag.lvl1Id}` : `collab-${tag.collaboratorId}`}
						className="tag"
						closable
						onClose={() => removeTag(tag.lvl1Id || tag.collaboratorId)}>
						{tag.collaborator ? <span className="com-tag mr-1">{tag.collaborator}</span> : <span className="com-tag mr-1">{tag.lvl1}</span>}
					</Tag>
				))}
				<Hint options={hints} allowTabFill>
					<input
						ref={inputRef}
						type="text"
						spellCheck="false"
						className="search-input"
						placeholder={input || selectedTags.length > 0 ? "" : "Search studies..."}
						value={input}
						onFocus={handleInputFocus}
						onChange={(e) => {
							setInput(e.target.value);
							e.target.setAttribute("data-value", e.target.value);
							onChange && onChange(e.target.value);
						}}
						onKeyDown={handleKeyDown}
					/>
				</Hint>
				<div className="ml-auto">
					<i className="material-symbols-outlined text-2xl text-gray-300 cursor-pointer" onClick={onClickSearchIcon}>
						search
					</i>
				</div>
			</div>
			{isDropdownVisible && (suggestions.length > 0 || collaboratorSuggestions.length > 0) && (
				<div id="suggestions" className="suggestions-box">
					{suggestions.length > 0 && (
						<div className="suggested-searches">
							<div className="legend">
								<span className="suggestion-text flex items-center text-blue-950 font-semibold">
									<i className="material-symbols-outlined text-2xl mr-2">local_library</i>Study
								</span>
								<span className="suggestion-tags">
									<Tag className="legend-badge">Custom Tags</Tag>
								</span>
							</div>
							{suggestions.map((suggestion, index) => (
								<div key={index} className="suggestion">
									<span className="suggestion-text flex items-center text-base-1 text-gray-600">
										<i className="material-symbols-outlined text-lg text-gray-300 mr-3">search</i>
										{highlightMatch(suggestion.level1Name, input)}
									</span>
									<span className="suggestion-tags">{renderTags(suggestion.lstLevel2s, input)}</span>
									<div className="suggestion-overlay"></div>
									<div className="suggestion-buttons">
										<button className="button" disabled={!suggestion.insightsAvailable} onClick={(e) => handleButtonClick(e, suggestion, "insights")}>
											View Insights
										</button>
										<button className="button" onClick={(e) => handleButtonClick(e, suggestion, "manage")}>
											Manage Study
										</button>
									</div>
								</div>
							))}
						</div>
					)}
					{collaboratorSuggestions.length > 0 && (
						<>
							<Divider className="mx-0 my-2 text-gray-900" />
							<div className="recent-searches">
								<div className="legend-other">
									<span className="flex items-center text-blue-950 font-semibold">
										<i className="material-symbols-outlined text-2xl mr-2">groups</i>Collaborators
									</span>
								</div>
								{collaboratorSuggestions.map((collaborator, index) => (
									<div key={index} className="suggestion collab-suggestion" onClick={() => handleCollaboratorClick(collaborator)}>
										<span className="suggestion-text flex items-center text-base-1 text-gray-600">
											<Avatar key={collaborator.collaboratorId} size="small" className="avatar mr-2">
												{getInitials(collaborator.collaboratorName)}
											</Avatar>
											{highlightMatch(collaborator.collaboratorName, input)}
										</span>
									</div>
								))}
							</div>
						</>
					)}
					{recentSearches.length > 0 && (
						<>
							<Divider className="mx-0 my-2 text-gray-900" />
							<div className="recent-searches">
								<div className="legend-other">
									<span className="flex items-center text-blue-950 font-semibold">
										<i className="material-symbols-outlined text-2xl mr-2">history</i>Recent Searches
									</span>
								</div>
								{filterRecentSearches(input).map((search, index) => (
									<div key={index} className="suggestion">
										<span className="suggestion-text flex items-center text-base-1 text-gray-600">
											<i className="material-symbols-outlined text-lg text-gray-300 mr-2">schedule</i>
											{highlightMatch(search.lvl1, input)}
										</span>
										<span className="suggestion-tags">
											<i className="material-symbols-outlined text-lg text-gray-300 mr-2" onClick={() => removeRecentSearch(index)}>
												close
											</i>
										</span>
									</div>
								))}
								{filterRecentSearches(input).length === 0 && (
									<div className="suggestion">
										<span className="suggestion-text flex items-center text-base-1 text-gray-600">No recent searches</span>
									</div>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default Searchbar;
