import React from "react";
import { Avatar, Checkbox, Popconfirm, Switch, Table, Tag, Tooltip } from "src/assets/imports/antdComponents";
import { User } from "../../models/interfaces";
import { getInitials, transformDateTime } from "src/pages/Studies/transformer";
import "./style.css";

const UserTable = (props) => {
	const { data, handleDelete, handleEdit, handleRowToggle, onSelectChange, selectedRowKeys } = props;

	const getUniqueRoles = () => {
		const rolesSet = new Set();
		data?.forEach((user) => {
			user?.profiles?.forEach((role) => rolesSet?.add(role));
		});
		return [...rolesSet]?.map((role) => ({ text: role, value: role }));
	};

	const columns = [
		// {
		// 	title: <Checkbox className="" onChange={(e) => onSelectChange(e.target.checked ? data.map((user) => user.collaborator_id) : [])} />,
		// 	dataIndex: "select",
		// 	key: "select",
		// 	width: "3%",
		// 	render: (_: any, record: User) => (
		// 		<Checkbox
		// 			onChange={(e) =>
		// 				onSelectChange(e.target.checked ? [...selectedRowKeys, record.collaborator_id] : selectedRowKeys.filter((key) => key !== record.collaborator_id))
		// 			}
		// 			checked={selectedRowKeys.includes(record.collaborator_id)}
		// 		/>
		// 	),
		// },
		{
			title: <span className="ml-12">Name</span>,
			dataIndex: "collaborator_name",
			key: "collaborator_name",
			width: "25%",
			render: (text: string, record: User) => (
				<div className="flex items-center">
					<Avatar className="navbar-icon ant-avatar-sm flex items-center justify-center mr-4">{getInitials(record.collaborator_name)}</Avatar>
					<div className="flex flex-col">
						<span className="text-base-1 font-medium secondary-color">{record.collaborator_name}</span>
						<span className="text-[x-small] secondary-color">{`${record.collaborator_id} | ${record.collaborator_email}`}</span>
					</div>
				</div>
			),
		},
		{
			title: "Last Updated",
			dataIndex: "lastUpdated",
			key: "lastUpdated",
			width: "15%",
			sorter: (a: User, b: User) => {
				const dateA = a?.version_history?.modified_dt ? new Date(a.version_history.modified_dt).getTime() : 0;
				const dateB = b?.version_history?.modified_dt ? new Date(b.version_history.modified_dt).getTime() : 0;
				return dateB - dateA;
			},
			render: (_: string, record: User) => (
				<>
					{record?.version_history && (
						<div className="text-sm secondary-color font-normal opacity-90 word-spacing-2">
							{record?.version_history?.modified_dt && <>Updated {transformDateTime(record.version_history.modified_dt)}</>} <br />
							{record?.version_history?.modified_by && <>by {record.version_history.modified_by?.full_name}</>}
						</div>
					)}
				</>
			),
		},
		{
			title: "Profile",
			dataIndex: "profiles",
			key: "profiles",
			filters: getUniqueRoles(),
			onFilter: (value, record) => record.profiles.includes(value),
			render: (profiles: string[]) => {
				const maxVisibleProfiles = 4;
				const extraTagsCount = profiles?.length - maxVisibleProfiles;

				return (
					<>
						<div className="flex gap-3 mb-1">
							{profiles?.slice(0, maxVisibleProfiles)?.map((role, index) => (
								<Tag key={index} className="profile-tag mr-1">
									{role}
								</Tag>
							))}
							{extraTagsCount > 0 && (
								<Tooltip
									overlayClassName="custom-tooltip"
									title={
										<div className="flex flex-wrap">
											{profiles?.slice(maxVisibleProfiles).map((role, index) => (
												<Tag key={index} className="profile-tag m-1">
													{role}
												</Tag>
											))}
										</div>
									}>
									<Tag className="profile-tag mr-1">+ {extraTagsCount} more</Tag>
								</Tooltip>
							)}
						</div>
					</>
				);
			},
		},
		{
			title: "Enable Edit Access",
			dataIndex: "access",
			key: "access",
			width: "10%",
			render: (_: string, record: User) => <Switch size="small" className="ml-12" onChange={() => handleRowToggle(record)} checked={record?.enable_access || false} />,
		},
		{
			title: "Actions",
			dataIndex: "actions",
			key: "actions",
			width: "6%",
			render: (_: string, record: User) => (
				<div className="flex space-x-2">
					<i onClick={() => handleEdit(record)} className="text-[1.5rem] material-symbols-outlined secondary-color mx-2 cursor-pointer">
						edit
					</i>
					<Popconfirm title="Confirm Deletion?" placement="leftBottom" onConfirm={() => handleDelete(record)} okText="Yes" cancelText="No">
						<i onClick={(e) => e.stopPropagation()} className={`text-[1.5rem] material-symbols-outlined text-[#EF4444] cursor-pointer section-icons`}>
							delete
						</i>
					</Popconfirm>
				</div>
			),
		},
	];
	return <Table size="small" scroll={{ y: "calc(100vh - 14.5rem)" }} columns={columns} dataSource={data} pagination={false} className="m-6" />;
};

export default UserTable;
