import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DeepDiveData } from "src/pages/Studies/models/interfaces";

const initialState: DeepDiveData = {
	collaborators: [],
	insight_details: { study_id: "" }, // Initially only contains study_id
	study_name: "",
	study_tags: [],
	user_access_type: "",
};

const deepDiveInsightSlice = createSlice({
	name: "deepdiveData",
	initialState: {
		deepDiveData: initialState,
	},
	reducers: {
		setDeepDiveData: (state, action: PayloadAction<any>) => {
			state.deepDiveData = action.payload;
		},
	},
});

export const { setDeepDiveData } = deepDiveInsightSlice.actions;

export default deepDiveInsightSlice.reducer;
