import axios from "axios";
import { apiService } from "../../../services";
import { IAddStudyData, ICompletionData, IDeleteFilePayload, IFileDownloadPayload, IPresignedUrlsResponse } from "../models/interfaces";
import { generateFileName } from "../transformer";
import { IfileDetails } from "src/pages/ManageStudies/models/interfaces";

export const getAllStudies = async () => {
	return await apiService.get("entities/basic");
};

export const getDropdownValues = async () => {
	return await apiService.get("masters/dropdown");
};

export const getAdminDropdownValues = async () => {
	return await apiService.get("admin/dropdown");
};

export const getAllUser = async () => {
	return await apiService.get("masters/allusers");
};

export const getMetaDetails = async () => {
	return await apiService.get("masters/metadata");
};

export const addNewStudy = async (values: IAddStudyData) => {
	return await apiService.post("entities/", values);
};

export const editStudy = async (values: IAddStudyData) => {
	return await apiService.put("entities/edit", values);
};

export const deleteStudy = async (study_id: string) => {
	return await apiService.delete("entities/", { study_id });
};

export const getFilesDetails = async (study_id: string) => {
	return await apiService.get("files/", { study_id });
};

export const deleteFiles = async (values: IDeleteFilePayload) => {
	return await apiService.post("files/", values);
};

export const getCollaborators = async (search_string: string) => {
	return await apiService.get("entities/collaborator", { search_string });
};

export const addCollaborators = async (values) => {
	return await apiService.post("entities/addCollaborator", values);
};

export const addUsers = async (values) => {
	return await apiService.post("entities/collaborator", values);
};

export const downloadFile = async (values: IFileDownloadPayload) => {
	const { file_name, file_type } = values;
	const fileName = generateFileName(file_type, file_name);
	try {
		const response: { url: string } = await apiService.get("files/download", values);
		// Fetch the file using the presigned URL
		const fileResponse = await fetch(response.url);
		if (fileResponse.ok) {
			const blob = await fileResponse.blob(); // Convert the response to a blob
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = fileName; // Use the original file name for download
			document.body.appendChild(a); // Append the anchor to the body
			a.click(); // Trigger the download
			document.body.removeChild(a); // Remove the anchor from the body
			window.URL.revokeObjectURL(url); // Clean up the object URL
		} else {
			throw new Error("Failed to download file");
		}
	} catch (err) {
		console.error("Error downloading file: ", err);
		throw err;
	}
};

export const getPresignedUrls = async (study_type_name: string, study_id: string, file_type: string, file_details: IfileDetails[]): Promise<IPresignedUrlsResponse[]> => {
	return await apiService.post("files/initiate_upload", { study_type_name, study_id, file_type, file_details });
};

export const getPresignedUrl = async (file_name: string, study_id: string, file_type): Promise<IPresignedUrlsResponse> => {
	return await apiService.post("files/get-presigned-url", { study_id, file_name, file_type });
};

export const completeMultipartUpload = async (completionData: ICompletionData) => {
	return await apiService.post("files/complete_upload", completionData);
};

// Function to upload a single chunk to S3
const uploadChunk = async (presignedUrl, file, index, CHUNK_SIZE, totalChunks, onProgress) => {
	const start = index * CHUNK_SIZE;
	const end = Math.min(start + CHUNK_SIZE, file.size);
	const chunk = file.slice(start, end); // Get the chunk from the file

	const { url } = presignedUrl; // Presigned S3 URL

	// Step 1: Upload the chunk to S3 using the presigned URL with `fetch`
	const response = await fetch(url, {
		method: "PUT",
		body: chunk,
		headers: {
			// You can add content type or other necessary headers here
			// 'Content-Type': 'application/octet-stream', // Optional: Set to binary upload
		},
	});

	// Check if the upload was successful
	if (!response.ok) {
		throw new Error(`Error uploading chunk ${index + 1}: ${response.statusText}`);
	}

	// Step 2: Calculate progress manually since fetch doesn't support `onUploadProgress`
	const percentCompleted = ((index + 1) / totalChunks) * 100;
	onProgress?.({ percent: percentCompleted });

	// Step 3: Extract ETag from the response headers
	const etag = response.headers.get("etag");

	// Return the ETag and PartNumber for this chunk
	return {
		PartNumber: index + 1,
		ETag: etag,
	};
};

// Function to handle the chunked upload process
export const uploadFileInChunksToS3 = async (presigned_urls_info, file, CHUNK_SIZE) => {
	const totalChunks = presigned_urls_info.length;

	const etags = await Promise.all(presigned_urls_info.map((presignedUrl, index) => uploadChunk(presignedUrl, file, index, CHUNK_SIZE, totalChunks, file.onProgress)));

	return etags;
};

export const uploadFileToS3 = async (url, file) => {
	// Step 1: Create a new AbortController to handle upload cancellation if needed
	const controller = new AbortController();
	const { signal } = controller;

	// Step 2: Upload the file to S3 using fetch
	const uploadResponse = await fetch(url, {
		method: "PUT",
		body: file,
		headers: {
			"Content-Type": file.type,
		},
		signal, // Attach the AbortController's signal to handle cancellation
	});

	// Step 3: Check if the upload was successful
	if (!uploadResponse.ok) {
		throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
	}

	// Step 4: Return the response from the fetch call
	return uploadResponse;
};
