import CheckCircleOutlined from "@ant-design/icons/lib/icons/CheckCircleOutlined";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import React, { useState } from "react";
import { Button, CloseOutlined, Modal } from "src/assets/imports/antdComponents";
import { IConfirmationObj } from "src/pages/Studies/models/interfaces";

interface TranscriptModalProps {
	confirmationData: IConfirmationObj;
	onCancel: () => void;
	onConfirm: () => void;
}

const ConfirmationDialog: React.FC<TranscriptModalProps> = ({ confirmationData, onCancel, onConfirm }) => {
	const { editType, name, status, visible } = confirmationData;
	const modalStyles = {
		header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4rem", padding: "1.25rem 1.5rem", margin: 0, background: "#FFF9E6" },
		body: { padding: "1.5rem 0.8rem 1rem 1.5rem" },
		mask: { backdropFilter: "blur(5px)" },
		footer: { padding: "1.5rem", margin: 0 },
		content: { padding: 0, margin: 0, width: "34rem" },
	};

	const getIcon = () => {
		switch (status) {
			case "success":
				return <CheckCircleOutlined className="text-green-600 text-xl" />;
			case "error":
				return <CloseCircleOutlined className="text-red-600 text-xl" />;
			case "warning":
				return <ExclamationCircleOutlined className="text-yellow-600 text-xl" />;
			default:
				return null;
		}
	};

	return (
		<Modal
			title={
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						{getIcon()}
						<span className="ml-2 secondary-color text-lg">
							{/* <span className="capitalize">{editType}</span> */}
							Unsaved Changes
						</span>
					</div>
					{/* Close Icon at the end */}
					<CloseOutlined className="text-lg cursor-pointer" onClick={onCancel} />
				</div>
			}
			open={visible}
			styles={modalStyles}
			onCancel={onCancel}
			centered
			footer={[
				<>
					<Button onClick={onCancel} className="mr-4">
						Cancel
					</Button>
					<Button type="primary" onClick={onConfirm} className="text-white px-10 rounded">
						Yes
					</Button>
				</>,
			]}
			closable={false}>
			<p className="text-base-1">
				Unsaved changes in <span className="font-semibold secondary-color">'{name}'</span> {editType} will be lost.
			</p>
			<p className="text-base-1 mt-6">Do you still want to close?</p>
		</Modal>
	);
};

export default ConfirmationDialog;
