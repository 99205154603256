// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-user-modal .ant-input-outlined[disabled] {
    color: #bfbfbf !important; /* Grey color for disabled input */
    background-color: #f5f5f5; /* Light grey background */
  }`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/components/AddUserModal/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,yBAAyB,EAAE,0BAA0B;EACvD","sourcesContent":[".add-user-modal .ant-input-outlined[disabled] {\n    color: #bfbfbf !important; /* Grey color for disabled input */\n    background-color: #f5f5f5; /* Light grey background */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
