import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import studiesSlice from "../pages/Studies/states/studiesSlice";
import manageDescriptionGuideSlice from "src/pages/ManageStudies/states/slice/manageDescriptionGuideSlice";
import unsavedChangesSlice from "src/pages/ManageStudies/states/slice/unsavedChangesSlice";
import manageStudySlice from "src/pages/ManageStudies/states/slice/manageStudySlice";
import manageTranscriptSlice from "src/pages/ManageStudies/states/slice/manageTranscriptSlice";
import askKuralSlice from "src/pages/AskKural/states/askKuralSlice";
import deepDiveInsightslice from "src/pages/Insights/states/deepDiveInsightslice";
import userManagementSlice from "src/pages/UserManagement/states/userManagementSlice";

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["studiesSlice"], // We'll only persist the studiesSlice
};

// Combine all reducers
const rootReducer = combineReducers({
	manageDescriptionGuideSlice,
	unsavedChangesSlice,
	manageStudySlice,
	manageTranscriptSlice,
	studiesSlice,
	askKuralSlice,
	deepDiveInsightslice,
	userManagementSlice,
});

// Create a persisted reducer for the studiesSlice
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["persist/PERSIST", "persist/REHYDRATE", "persist/PURGE"],
			},
		}),
	devTools: {
		name: "Kural Store",
	},
});

// Create the persistor
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
