import React, { useState, useEffect } from "react";
import { SearchOutlined, Modal, Table, Input, Switch, Space, Avatar, EyeOutlined } from "src/assets/imports/antdComponents";
import { ICollaboratorData, ICollaboratorsTableProps, userAccessType } from "../../models/interfaces";
import { getInitials } from "../../transformer";
import { addUsers, getAllUser } from "../../serviceHelper";
import { updateSelectedStudy, updateStudyCollaboratorData } from "../../states/studiesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";

const modalStyles = {
	header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4.5rem", padding: "1.25rem 1.5rem", margin: 0 },
	body: { padding: "1.5rem", height: "35rem" },
	mask: { backdropFilter: "blur(5px)" },
	footer: { padding: "0.5rem 1.5rem", margin: 0 },
	content: { padding: 0, margin: 0 },
};

const AddCollaboratorModal: React.FC<ICollaboratorsTableProps> = ({ authorUser, fullAccessUsers, isModalOpen, handleCancel, studyId }) => {
	const dispatch = useDispatch();
	const [collaborators, setCollaborators] = useState<ICollaboratorData[]>([]);
	const [searchText, setSearchText] = useState("");
	const [loading, setLoading] = useState(true);
	const [loadingSwitch, setLoadingSwitch] = useState<{ [key: string]: boolean }>({});
	const [fullAccessUserState, setFullAccessUserState] = useState<ICollaboratorData[]>(fullAccessUsers);
	const [initialLoad, setInitialLoad] = useState(true);
	const metaDetails = useSelector((state: RootState) => state.studiesSlice.metaDetails);
	const logInUser = metaDetails?.id;

	useEffect(() => {
		if (isModalOpen && initialLoad) {
			fetchCollaborators();
			setInitialLoad(false);
		}
	}, [isModalOpen, initialLoad]);

	const fetchCollaborators = async () => {
		try {
			const response: any = await getAllUser();
			if (response.data) {
				const updatedCollaborators = response?.data.map((collaborator) => {
					const fullAccess = fullAccessUserState.some((user) => user.collaborator_id === collaborator.collaborator_id);
					return {
						...collaborator,
						user_access_type: fullAccess ? userAccessType.edit : userAccessType.view,
					};
				});

				const sortedCollaborators = updatedCollaborators.sort((a, b) => {
					if (a.collaborator_id === authorUser?.user_id) return -1;
					if (b.collaborator_id === authorUser?.user_id) return 1;

					const aIsFullAccess = fullAccessUserState.some((user) => user.collaborator_id === a.collaborator_id);
					const bIsFullAccess = fullAccessUserState.some((user) => user.collaborator_id === b.collaborator_id);

					if (aIsFullAccess && !bIsFullAccess) return -1;
					if (!aIsFullAccess && bIsFullAccess) return 1;

					return 0;
				});

				setCollaborators(sortedCollaborators);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleSearch = (value: string) => {
		setSearchText(value);
	};

	const handleToggle = async (record: ICollaboratorData) => {
		setLoadingSwitch((prevState) => ({
			...prevState,
			[record.collaborator_id]: true,
		}));

		try {
			const updatedAccessType = record.user_access_type === userAccessType.edit ? userAccessType.view : userAccessType.edit;
			const collabDetails = {
				collaborator_id: record.collaborator_id,
				collaborator_name: record.collaborator_name,
				collaborator_email: record.collaborator_email,
				user_access_type: updatedAccessType,
			};

			const responseObj = {
				...collabDetails,
				user_access_type: updatedAccessType === userAccessType.edit ? true : false,
				study_id: studyId,
			};

			const response = await addUsers(responseObj);

			if (response) {
				const updatedCollaborators = collaborators.map((collaborator) =>
					collaborator.collaborator_id === record.collaborator_id ? { ...collaborator, user_access_type: updatedAccessType } : collaborator
				);
				setCollaborators(updatedCollaborators);

				let updatedFullAccessUsers;
				if (updatedAccessType === userAccessType.edit) {
					updatedFullAccessUsers = [...fullAccessUserState, record];
				} else {
					updatedFullAccessUsers = fullAccessUserState.filter((user) => user.collaborator_id !== record.collaborator_id);
				}
				setFullAccessUserState(updatedFullAccessUsers);

				dispatch(
					updateStudyCollaboratorData({
						collab_details: updatedFullAccessUsers,
						study_id: studyId,
					})
				);
				dispatch(
					updateSelectedStudy({
						study_collaborators: updatedFullAccessUsers,
					})
				);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoadingSwitch((prevState) => ({
				...prevState,
				[record.collaborator_id]: false,
			}));
		}
	};

	const columns = [
		{
			title: "Collaborator Name",
			dataIndex: "collaborator_name",
			key: "collaborator_name",
			filteredValue: [searchText],
			onFilter: (value: string, record: ICollaboratorData) =>
				record?.collaborator_name?.toLowerCase().includes(value.toLowerCase()) || record?.collaborator_email?.toLowerCase().includes(value.toLowerCase()),
			render: (_: any, record: ICollaboratorData) => (
				<div className="flex items-center">
					<Avatar size="small" className="navbar-icon flex items-center justify-center mr-4">
						{getInitials(record.collaborator_name)}
					</Avatar>
					<div className="flex flex-col">
						<span className="text-base-1 font-medium secondary-color">{record.collaborator_name}</span>
						<span className="text-[x-small] secondary-color">{record.collaborator_email}</span>
					</div>
				</div>
			),
		},
		{
			title: "View",
			key: "view",
			render: () => (
				<span className="flex items-center justify-center">
					<EyeOutlined className="text-xl font-extrabold primary-color" />
				</span>
			),
			width: "12.5%",
		},
		{
			title: "Edit",
			key: "edit",
			render: (text: string, record: ICollaboratorData) => (
				<Switch
					size="small"
					checked={record?.user_access_type === userAccessType.edit}
					onChange={() => handleToggle(record)}
					disabled={record?.collaborator_id === authorUser?.user_id || record?.collaborator_id === logInUser}
					loading={loadingSwitch[record?.collaborator_id]}
				/>
			),
			width: "12.5%",
		},
	];

	const dataSource = collaborators
		.filter((collaborator) => [collaborator.collaborator_name, collaborator.collaborator_email].some((field) => field.toLowerCase().includes(searchText.toLowerCase())))
		.map((collaborator) => ({
			...collaborator,
			key: collaborator.collaborator_id + collaborator.collaborator_email,
		}));

	return (
		<Modal title="Add Collaborators" open={isModalOpen} onCancel={handleCancel} width={450} footer={[]} centered styles={modalStyles}>
			<div>
				<Input prefix={<SearchOutlined />} placeholder="Search collaborators" className="mb-4" value={searchText} onChange={(e) => handleSearch(e.target.value)} />
				<Table
					size="small"
					rowKey="collaborator_id"
					scroll={{ y: 310 }}
					pagination={false}
					columns={columns}
					dataSource={dataSource}
					loading={loading}
					rowClassName={(record) => (record?.collaborator_id === authorUser?.user_id || record?.collaborator_id === logInUser ? "disabled-row" : "")}
					locale={{
						emptyText: "Loading...",
					}}
				/>
			</div>
		</Modal>
	);
};

export default AddCollaboratorModal;
