import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ManageDescriptionGuideState, Section, SubSection } from "../../models/interfaces";

const initialState: ManageDescriptionGuideState = {
	sections: [],
	activeSectionId: null,
	edit: {},
	upload_details: {},
	dg_name: "",
};

const manageDescriptionGuideSlice = createSlice({
	name: "manageDescriptionGuide",
	initialState,
	reducers: {
		setActiveSection(state, action: PayloadAction<number>) {
			state.activeSectionId = action.payload;
		},
		setEditLock(state, action: PayloadAction<{}>) {
			state.edit = action.payload;
		},
		setUploadDetails(state, action: PayloadAction<{}>) {
			state.upload_details = action.payload;
		},
		setDiscussionGuideName(state, action: PayloadAction<string>) {
			state.dg_name = action.payload;
		},
		updateSectionName(state, action: PayloadAction<{ sectionId: number; value: string }>) {
			const { sectionId, value } = action.payload;
			const section = state.sections.find((s) => s.section_id == sectionId);
			section.section_name = value;
		},
		addSection(state) {
			const newSubSection: SubSection = {
				sub_section_id: Date.now(),
				sub_section_name: "",
				questions: [],
			};
			const newSection: Section = {
				section_id: Date.now(),
				section_name: `New Section`,
				sub_section: [],
			};
			newSection.sub_section.push(newSubSection);
			state.sections.push(newSection);
			state.activeSectionId = newSection.section_id;
		},
		deleteSection(state, action: PayloadAction<{ sectionId: number }>) {
			const { sectionId } = action.payload;
			state.sections = state.sections.filter((s) => s.section_id !== sectionId);
			if (state.sections.length === 0) {
				state.activeSectionId = null;
			} else {
				state.activeSectionId = state.sections[0].section_id;
			}
		},
		addSubSection(state, action: PayloadAction<number>) {
			const section = state.sections.find((s) => s.section_id == action.payload);
			if (section) {
				const newSubSection: SubSection = {
					sub_section_id: Date.now(),
					sub_section_name: "",
					questions: [],
				};
				section.sub_section.push(newSubSection);
			}
		},
		addQuestion(state, action: PayloadAction<{ sectionId: number; subSectionId: number }>) {
			const { sectionId, subSectionId } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			if (section) {
				const subSection = section.sub_section.find((ss) => ss.sub_section_id === subSectionId);
				if (subSection) {
					const newQuestion = {
						question_id: Date.now(), // Unique ID for the new question
						question_name: "",
					};
					subSection.questions.push(newQuestion);
				}
			}
		},
		updateTextValue(state, action: PayloadAction<{ sectionId: number; subSectionId: number; questionId?: number; textValue: string }>) {
			const { sectionId, subSectionId, questionId, textValue } = action.payload;

			const section = state.sections.find((s) => s.section_id === sectionId);

			if (section) {
				const subSection = section.sub_section.find((ss) => ss.sub_section_id === subSectionId);

				if (subSection) {
					if (questionId !== undefined) {
						const question = subSection.questions.find((q) => q.question_id === questionId);
						console.log(current(subSection));
						if (question) {
							question.question_name = textValue;
						}
					} else {
						subSection.sub_section_name = textValue;
					}
				}
			}
		},
		updateSections(state, action: PayloadAction<Section[]>) {
			state.sections = action.payload;
		},
		updateSubSections(state, action: PayloadAction<{ sectionId: number; subSections: SubSection[] }>) {
			const { sectionId, subSections } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			section.sub_section = subSections;
		},
		deleteSubSection(state, action: PayloadAction<{ sectionId: number; subSectionId: number }>) {
			const { sectionId, subSectionId } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			if (section) {
				section.sub_section = section.sub_section.filter((ss) => ss.sub_section_id !== subSectionId);
			}
		},
		deleteQuestion(state, action: PayloadAction<{ sectionId: number; subSectionId: number; questionId: number }>) {
			const { sectionId, subSectionId, questionId } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			if (section) {
				const subSection = section.sub_section.find((ss) => ss.sub_section_id === subSectionId);
				if (subSection) {
					subSection.questions = subSection.questions.filter((q) => q.question_id !== questionId);
				}
			}
		},
		copySubSection(state, action: PayloadAction<{ sectionId: number; subSectionId: number }>) {
			const { sectionId, subSectionId } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			if (section) {
				const subSectionIndex = section.sub_section.findIndex((ss) => ss.sub_section_id === subSectionId);
				if (subSectionIndex > -1) {
					const subSection = section.sub_section[subSectionIndex];
					const newSubSection: SubSection = {
						...subSection,
						sub_section_id: Date.now(),
					};
					section.sub_section.splice(subSectionIndex + 1, 0, newSubSection);
				}
			}
		},
		copyQuestion(state, action: PayloadAction<{ sectionId: number; subSectionId: number; questionId: number }>) {
			const { questionId, sectionId, subSectionId } = action.payload;
			const section = state.sections.find((s) => s.section_id === sectionId);
			if (section) {
				const subSection = section.sub_section.find((ss) => ss.sub_section_id === subSectionId);
				if (subSection) {
					const question = subSection.questions.find((q) => q.question_id === questionId);
					const questionIndex = subSection.questions.findIndex((q) => q.question_id === questionId);

					if (question) {
						const newQuestion = {
							...question,
							question_id: Date.now(),
						};
						subSection.questions.splice(questionIndex + 1, 0, newQuestion);
					}
				}
			}
		},
		createDiscussionGuideName(state, action: PayloadAction<{ dg_name: string }>) {
			const { dg_name } = action.payload;
			state.upload_details = { ...state.upload_details, filename: dg_name };
		},
	},
});

export const {
	addQuestion,
	addSection,
	addSubSection,
	copyQuestion,
	copySubSection,
	createDiscussionGuideName,
	deleteQuestion,
	deleteSection,
	deleteSubSection,
	setActiveSection,
	setDiscussionGuideName,
	setEditLock,
	setUploadDetails,
	updateSectionName,
	updateSections,
	updateSubSections,
	updateTextValue,
} = manageDescriptionGuideSlice.actions;

export default manageDescriptionGuideSlice.reducer;
