import React, { Suspense, useCallback, useState } from "react";
import { Button, Form, Input, SearchOutlined } from "src/assets/imports/antdComponents";
import { Switch } from "antd";
import { IResponse } from "src/pages/Studies/models/interfaces";
import { DeleteUsers, saveProfileDetails } from "../../serviceHelper";
import { IProfile } from "../../models/interfaces";
import AddProfileModal from "../AddProfileModal";
import { getAdminDropdownValues } from "src/pages/Studies/serviceHelper";
import { useDispatch } from "react-redux";
import { setAdminDropDownMaster } from "../../states/userManagementSlice";

const SubHeader = (props) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { errorMsg, handleOpenUserModal, handleSearch, searchText, selectedRowKeys, successMsg } = props;
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [profileData, setProfileData] = useState<IProfile>({ profile: "" });

	const validateForm = useCallback(async () => {
		try {
			await form.validateFields();
			return true;
		} catch (err) {
			console.error("Form validation error:", err);
			return false;
		}
	}, [form]);

	async function fetchAdminDropdownValues() {
		try {
			const response: IResponse = await getAdminDropdownValues();
			const data = response?.data;
			if (data) {
				dispatch(setAdminDropDownMaster(data));
			}
		} catch (error) {
			console.error("Error:", error);
		}
	}

	const handleAddProfile = async () => {
		const isFormValid = await validateForm();
		if (isFormValid) {
			try {
				const response: IResponse = await saveProfileDetails(profileData);
				successMsg(response.message || "User saved successfully!");
				handleOpenRoleModal();
			} catch (err) {
				errorMsg("Something went wrong!");
				console.log("error", err);
			} finally {
				fetchAdminDropdownValues();
			}
		}
	};

	const handleOpenRoleModal = () => {
		setIsModalVisible(!isModalVisible);
		setProfileData({ profile: "" });
	};

	const handleDelete = async () => {
		try {
			const response: IResponse = await DeleteUsers(selectedRowKeys);
			successMsg(response.message || "Users deleted successfully!");
		} catch (error) {
			errorMsg("Something went wrong!");
			console.log("eror", error);
		}
	};

	return (
		<>
			<div className="subHeader-nav flex items-center justify-between p-1 px-5 w-full">
				<div className="flex items-center flex-1 gap-4">
					<h2 className="text-2xl font-normal whitespace-nowrap flex items-center space-x-1">
						<i onClick={(e) => e.stopPropagation()} className="text-[1.5rem] material-symbols-outlined tertiary-color cursor-pointer section-icons">
							group
						</i>
						<span>Manage User</span>
					</h2>
					<span className="border-l border-gray-200 h-10"></span>
					<Input placeholder="Search users..." prefix={<SearchOutlined />} value={searchText} onChange={handleSearch} className="w-[35rem]" />
				</div>

				<div className="flex items-center justify-end flex-1 space-x-2 gap-3">
					{/* <span className="flex items-center space-x-1">
						<span className={`text-base-1 ${selectedRowKeys.length > 0 ? "secondary-color" : "text-[#bfc0c7]"} font-semibold`}>Enable Selected:</span>
						<Switch size="small" onChange={handleEnableSelected} checked={toggle} />
					</span> */}
					{/* <span className="border-l border-gray-200 h-10"></span> */}
					{/* <span className="flex items-center space-x-1">
						<span className={`text-base-1 ${selectedRowKeys.length > 0 ? "secondary-color" : "text-[#bfc0c7]"} font-semibold`}>Delete Selected:</span>
						<i
							onClick={selectedRowKeys.length > 0 ? () => handleDelete() : () => {}}
							className={`text-[1.5rem] material-symbols-outlined ${
								selectedRowKeys.length > 0 ? "text-[#EF4444] cursor-pointer" : "text-[#bfc0c7] cursor-not-allowed"
							} section-icons`}>
							delete
						</i>
					</span> */}
					<Button onClick={handleOpenRoleModal} type="default">
						Add Profile
					</Button>
					<Button onClick={handleOpenUserModal} type="primary">
						Add User
					</Button>
				</div>
			</div>
			<Suspense fallback={<></>}>
				{isModalVisible && (
					<AddProfileModal
						profileData={profileData}
						setProfileData={setProfileData}
						form={form}
						isModalVisible={isModalVisible}
						setIsModalVisible={setIsModalVisible}
						handleSave={handleAddProfile}
						handleClose={handleOpenRoleModal}
					/>
				)}
			</Suspense>
		</>
	);
};

export default SubHeader;
