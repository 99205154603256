import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
	transcriptId: null,
	file_id: null,
	transcriptsDetails: {
		details: {},
		transcripts: [],
	},
};

const manageTranscript = createSlice({
	name: "manageTranscript",
	initialState,
	reducers: {
		setTranscriptId(state, action: PayloadAction<string>) {
			state.transcriptId = action.payload;
		},
		setTranscriptFileId(state, action: PayloadAction<string>) {
			state.file_id = action.payload;
		},
		setTranscriptData(state, action: PayloadAction<{ details: {}; transcripts: {}[] }>) {
			state.transcriptsDetails = action.payload;
		},
		changeTranscriptData(state, action: PayloadAction<{ index: number; transcript: any }>) {
			let { index, transcript } = action.payload;
			let findIndex = state.transcriptsDetails.transcripts.findIndex((ele) => ele.dialog_id === index);
			state.transcriptsDetails.transcripts[findIndex] = transcript;
		},
		splitMergeTranscriptData(state, action: PayloadAction<{ index: number; transcript: any }>) {
			let { index, transcript } = action.payload;
			let findIndex = state.transcriptsDetails.transcripts.findIndex((ele) => ele.dialog_id === index);
			state.transcriptsDetails.transcripts.splice(findIndex + 1, 0, transcript);
		},
		deleteSplitTranscript(state, action: PayloadAction<{ index: string }>) {
			let { index } = action.payload;
			let findIndex = state.transcriptsDetails.transcripts.findIndex((ele) => ele.dialog_id === index);
			if (findIndex !== -1) {
				state.transcriptsDetails.transcripts.splice(findIndex, 1);
			}
		},
	},
});

export const { setTranscriptId, setTranscriptFileId, setTranscriptData, changeTranscriptData, splitMergeTranscriptData, deleteSplitTranscript } = manageTranscript.actions;
export default manageTranscript.reducer;
