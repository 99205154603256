import { MenuProps } from "src/assets/imports/antdComponents";

export interface AskKuralModalProps {
	isModalOpen: boolean;
	handleOk: () => void;
	handleCancel: () => void;
}

export enum StudyType {
	Transcripts = "transcripts",
	Reports = "reports",
}
export enum QueriesConfigType {
	AskKural = "ask-kural",
	Cognitive = "cognitive",
}

export type MenuItem = Required<MenuProps>["items"][number];

export interface IQuery {
	id: string;
	name: string;
	file_id: string[];
	meta_id: string[];
	entity_id: string[];
	search_type: string;
	messages?: { text: string; type: "sent" | "received" }[];
	result_rating_desc?: string;
	rating?: number;
	ReferenceItems?: IReferenceItem[];
	isSaved?: boolean;
}

export interface IChatContainerProps {
	messages: { text: string; type: "sent" | "received"; is_notification?: boolean }[];
	ratingGiven?: number;
	ratingDesc?: string;
	isLoading: boolean;
	isProcessComplete: boolean;
	referenceItems: IReferenceItem[];
	isSaved?: boolean;
	setIsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
	isDrawerVisible: boolean;
	onSave: () => void;
	onSubmitRating: (rating: number, comment: string) => Promise<void>;
	isExpanded: boolean;
	setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IStreamResponse {
	event: string;
	id?: string;
	data: string;
}

export interface IQueriesPanelProps {
	onNewQueryClick: () => void;
	onDeleteQuery: (query_id: string) => void;
	setResetKey: (key: number) => void;
}

export interface IRightPanelProps {
	transcripts: any[];
	reports: any[];
	resetKey: number;
}

export interface IReferenceItemProps {
	item: IReferenceItem;
	onTranscriptClick: (item: IReferenceItem) => void;
	activeCitation: number;
}

export interface ITranscriptDataInter {
	numberOfPatients?: number;
	numberOfSearchResults?: number;
	emotionsCounts: any;
	statesResults: any;
	gptSummary?: any;
	gptSummaryText?: string;
	headers?: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		content?: string;
		context: string;
		context_preview: string;
		patientId: number;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
		storyDialogues: any;
		wave: string;
		geography: string;
		fileName: string;
		source: string;
		studyName: string;
		studyId: number;
		FileType?: string;
	}[];
}

export interface ITranscriptItem {
	time?: string;
	dialog_id: number;
	speaker_type: string;
	dialog: string;
}

export interface IReferenceItem {
	context?: IDialogueItem[];
	context_preview: IDialogueItem[];
	file_name: string;
	file_type: string;
	entity_name?: string;
	tr_id?: string;
	tags: any[];
	conversation_id?: number;
	citation_id?: number;
	transcriptItems?: ITranscriptItem[];
	onTranscriptClick?: (transcript: IReferenceItem) => void;
	report_id?: string;
	document_id?: number;
	pdf_url?: string;
	cimg_path_url?: string;
}

export interface IReferenceDrawerProps {
	visible: boolean;
	isExpanded: boolean;
	onClose: () => void;
	setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
	referenceItems: IReferenceItem[];
	activeCitation: number;
}

export interface IDialogueItem {
	dialog_no?: number;
	speaker_type?: string;
	text: string;
	page_no?: number;
}

export interface IMenuInfo {
	key: string;
	domEvent: React.MouseEvent<HTMLElement>;
}

interface ITagType {
	value: string;
	label: string;
}

export interface ITagDisplayProps {
	tags: ITagType[];
	highlightText: string;
	maxVisibleTags?: number;
}

export interface ISearchBarProps {
	value: string;
	onChange: (value: string) => void;
	onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface IDataset {
	key: string;
	label: string;
	tags: Array<{ value: string; label: string; category: string }>;
}

export interface IStudy {
	study_id: string;
	study: string;
	tags: Array<{ value: string; label: string; category: string }>;
	datasets: IDataset[];
}

export interface IDataConfig {
	[StudyType.Transcripts]: IStudy[];
	[StudyType.Reports]: IStudy[];
}

export interface ITag {
	value: string;
	label: string;
	category: string;
}

export interface IOptionData {
	label: string;
	value: string;
	key: string;
}

export interface IOptionGroup {
	label: string;
	title: string;
	options: IOptionData[];
}

export interface ITranscriptViewProps {
	transcriptName: string;
	createdBy: string;
	transcriptItems?: ITranscriptItem[];
	onClose: () => void;
	isLoadingTranscript: boolean;
	contextIds: number[];
	isExpanded: boolean;
}
export interface IReportViewProps {
	reportName: string;
	pdfUrl: string;
	onClose: () => void;
	loading?: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	pageNo: number;
	isExpanded: boolean;
}

export interface IFlattenedJsonItem {
	display: string;
	id: string;
}
