// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
	font-size: 12px;
}

body {
	font-family: "Open Sans", sans-serif;
	letter-spacing: 0.3px;
	word-spacing: 2px;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
button,
input,
textarea {
	font-family: "Open Sans", sans-serif !important;
}

.primary-color {
	color: #4cbb96 !important;
}

.secondary-color {
	color: #112e65 !important;
}

.tertiary-color {
	color: #828389 !important;
}

.text-base-1 {
	font-size: 1.04166667rem !important;
}

.word-spacing-1 {
	word-spacing: 1px !important;
}

.word-spacing-2 {
	word-spacing: 0.5px !important;
}

.word-spacing-3 {
	word-spacing: 0px !important;
}

/* For WebKit browsers */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background: #f2f2f2;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #cecdcd;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #cecdcd;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/theme.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,oCAAoC;CACpC,qBAAqB;CACrB,iBAAiB;CACjB,mCAAmC;CACnC,kCAAkC;CAClC,uCAAuC;AACxC;;AAEA;;;;;;;;;;;;CAYC,+CAA+C;AAChD;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,mCAAmC;AACpC;;AAEA;CACC,4BAA4B;AAC7B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,4BAA4B;AAC7B;;AAEA,wBAAwB;AACxB;CACC,UAAU;CACV,WAAW;AACZ;;AAEA;CACC,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":["html {\n\tfont-size: 12px;\n}\n\nbody {\n\tfont-family: \"Open Sans\", sans-serif;\n\tletter-spacing: 0.3px;\n\tword-spacing: 2px;\n\t-webkit-font-smoothing: antialiased;\n\ttext-rendering: optimizeLegibility;\n\ttext-shadow: 0 0 1px rgba(0, 0, 0, 0.1);\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\np,\nspan,\ndiv,\nbutton,\ninput,\ntextarea {\n\tfont-family: \"Open Sans\", sans-serif !important;\n}\n\n.primary-color {\n\tcolor: #4cbb96 !important;\n}\n\n.secondary-color {\n\tcolor: #112e65 !important;\n}\n\n.tertiary-color {\n\tcolor: #828389 !important;\n}\n\n.text-base-1 {\n\tfont-size: 1.04166667rem !important;\n}\n\n.word-spacing-1 {\n\tword-spacing: 1px !important;\n}\n\n.word-spacing-2 {\n\tword-spacing: 0.5px !important;\n}\n\n.word-spacing-3 {\n\tword-spacing: 0px !important;\n}\n\n/* For WebKit browsers */\n::-webkit-scrollbar {\n\twidth: 8px;\n\theight: 8px;\n}\n\n::-webkit-scrollbar-track {\n\tbackground: #f2f2f2;\n\tborder-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n\tbackground: #cecdcd;\n\tborder-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n\tbackground: #cecdcd;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
