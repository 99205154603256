import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	adminDropDownMaster: [],
};

const userManagementSlice = createSlice({
	name: "userManagement",
	initialState,
	reducers: {
		setAdminDropDownMaster: (state, action) => {
			state.adminDropDownMaster = action.payload;
		},
	},
});

export const { setAdminDropDownMaster } = userManagementSlice.actions;

export default userManagementSlice.reducer;
