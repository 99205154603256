import axios from "axios";
import { Store } from "@reduxjs/toolkit";
import { RootState } from "src/store";
let store: Store;
export const injectStore = (_store: Store) => {
	store = _store;
};

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
});

axiosInstance.interceptors.request.use(
	(config) => {
		config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
		config.withCredentials = true;
		if (store) {
			const state = store.getState() as RootState;
			const userProfile = state.studiesSlice.userProfile;
			if (userProfile) config.headers["profile-id"] = userProfile;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		console.error("ERR...", error.response?.status);
		if (error.response?.status === 401 || error.response?.status === 403) {
			window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
