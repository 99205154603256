import React from "react";
import { useLocation } from "react-router-dom";
import { List } from "src/assets/imports/antdComponents";
import CustSkeleton from "../Skeleton";
import SubHeader from "../SubHeader";

const Loading: React.FC = () => {
	const location = useLocation();
	const path = location.pathname;

	const isSpecificRoute = path === "/" || path === "/studies";

	return (
		<>
			{isSpecificRoute ? (
				<>
					<SubHeader title="Studies" tags={[]} />
					<div className="m-6">
						<List
							grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
							dataSource={[0, 1, 2, 3, 4]}
							renderItem={(item) => (
								<List.Item key={item}>
									<CustSkeleton type="card" className="custom-card h-[21rem] pt-4" />
								</List.Item>
							)}
						/>
					</div>
				</>
			) : (
				<div role="status" className="flex justify-center items-center w-full h-full">
					<div className="animate-spin rounded-full h-10 w-10 border-t-4" style={{ borderTopColor: "#4CBB96" }}></div>
					<span className="sr-only">Loading...</span>
				</div>
			)}
		</>
	);
};

export default Loading;
