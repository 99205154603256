// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout .top-navbar {
	background-color: #fff;
	height: 4.8rem;
	padding: 0 1.5rem;
	line-height: normal;
	z-index: 1000;
	border-bottom: 1px solid #5e80b740;
	/* box-shadow: 2px 2px 16px 0px #0000000f !important; */
}

.notification-badge .ant-badge-count::after {
	display: none;
}

.ask-kural-btn {
	background: linear-gradient(98.41deg, #222660 0%, #464ec6 100%) !important;
}

.ask-kural-btn:hover,
.ask-kural-btn:focus {
	background: linear-gradient(98.41deg, #222660 0%, #464ec6 100%);
	color: white;
}

.custom-select .ant-select-selector {
	font-size: 1.04166667rem;
}

.custom-dropdown .ant-select-item-option-selected,
.custom-dropdown .ant-select-item-option-active {
	background-color: #f4f5f7 !important;
	color: #5e80b7;
}

.custom-dropdown .ant-select .ant-select-arrow {
	color: #5e80b7;
}
.custom-dropdown .ant-select-item-option-content {
	font-size: 1.04166667rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/TopNavbar/style.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,cAAc;CACd,iBAAiB;CACjB,mBAAmB;CACnB,aAAa;CACb,kCAAkC;CAClC,uDAAuD;AACxD;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,0EAA0E;AAC3E;;AAEA;;CAEC,+DAA+D;CAC/D,YAAY;AACb;;AAEA;CACC,wBAAwB;AACzB;;AAEA;;CAEC,oCAAoC;CACpC,cAAc;AACf;;AAEA;CACC,cAAc;AACf;AACA;CACC,mCAAmC;AACpC","sourcesContent":[".ant-layout .top-navbar {\n\tbackground-color: #fff;\n\theight: 4.8rem;\n\tpadding: 0 1.5rem;\n\tline-height: normal;\n\tz-index: 1000;\n\tborder-bottom: 1px solid #5e80b740;\n\t/* box-shadow: 2px 2px 16px 0px #0000000f !important; */\n}\n\n.notification-badge .ant-badge-count::after {\n\tdisplay: none;\n}\n\n.ask-kural-btn {\n\tbackground: linear-gradient(98.41deg, #222660 0%, #464ec6 100%) !important;\n}\n\n.ask-kural-btn:hover,\n.ask-kural-btn:focus {\n\tbackground: linear-gradient(98.41deg, #222660 0%, #464ec6 100%);\n\tcolor: white;\n}\n\n.custom-select .ant-select-selector {\n\tfont-size: 1.04166667rem;\n}\n\n.custom-dropdown .ant-select-item-option-selected,\n.custom-dropdown .ant-select-item-option-active {\n\tbackground-color: #f4f5f7 !important;\n\tcolor: #5e80b7;\n}\n\n.custom-dropdown .ant-select .ant-select-arrow {\n\tcolor: #5e80b7;\n}\n.custom-dropdown .ant-select-item-option-content {\n\tfont-size: 1.04166667rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
