import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal, Select } from "src/assets/imports/antdComponents";
import { RootState } from "src/store";

const modalStyles = {
	header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4.5rem", padding: "1.25rem 1.5rem", margin: 0 },
	body: { padding: "1.5rem" },
	mask: { backdropFilter: "blur(5px)" },
	footer: { padding: "1rem 1.5rem", margin: 0 },
	content: { padding: 0, margin: 0 },
};

const AddProfileModal = (props) => {
	const { form, handleClose, handleSave, isModalVisible, profileData, setProfileData } = props;
	const adminDropdownMaster = useSelector((state: RootState) => state.userManagementSlice.adminDropDownMaster);
	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const validateName = (_, value) => {
		// Allow name to be empty, but if provided, must meet criteria
		if (value) {
			const nameRegex = /^[a-zA-Z0-9-_]+$/; // Removed the space character from the regex
			if (value.length > 31) {
				setIsSaveDisabled(true);
				return Promise.reject("Input must be less than 32 characters.");
			}
			if (value.includes(" ")) {
				// Check specifically for spaces
				setIsSaveDisabled(true);
				return Promise.reject("Spaces are not allowed.");
			}
			if (!nameRegex.test(value)) {
				setIsSaveDisabled(true);
				return Promise.reject("Allowed special characters: '-','_' ");
			}
			if (adminDropdownMaster.some((item) => item.toLowerCase() === value.toLowerCase())) {
				setIsSaveDisabled(true);
				return Promise.reject("Profile already exists.");
			}
		}
		setIsSaveDisabled(false);
		return Promise.resolve();
	};

	const handleChange = (value, fieldName) => {
		setProfileData((prevData) => ({
			...prevData,
			[fieldName]: value,
		}));
	};

	useEffect(() => {
		form.resetFields();
	}, []);

	return (
		<Modal
			title="Add Profile"
			open={isModalVisible}
			onCancel={handleClose}
			className="add-profile-modal"
			styles={modalStyles}
			footer={
				<div className="flex justify-end space-x-4">
					<Button onClick={handleClose}>Cancel</Button>
					<Button type="primary" onClick={handleSave} disabled={isSaveDisabled}>
						Save
					</Button>
				</div>
			}>
			<Form form={form} layout="vertical">
				<Form.Item label="Profile Name" name="profile" rules={[{ required: true, message: "Profile is required" }, { validator: validateName }]}>
					<Input autoComplete="off" placeholder="Enter profile name" value={profileData.profile} onChange={(e) => handleChange(e.target.value, "profile")} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddProfileModal;
