import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuery, QueriesConfigType, StudyType } from "../models/interfaces";

interface QueriesState {
	savedQueries: IQuery[];
	selectedQuery: IQuery | null;
	newQuery: IQuery | null;
	loadingState: boolean;
	studyType: StudyType;
	conversationId: string;
	selectedQueriesConfig: QueriesConfigType;
	isCompDisabled?: boolean;
}

const initialState: QueriesState = {
	savedQueries: [],
	selectedQuery: null,
	newQuery: null,
	loadingState: false,
	studyType: null,
	conversationId: null,
	selectedQueriesConfig: QueriesConfigType.AskKural,
	isCompDisabled: false,
};

const queriesSlice = createSlice({
	name: "queries",
	initialState,
	reducers: {
		setAllQueries(state, action: PayloadAction<IQuery[]>) {
			state.savedQueries = action.payload;
		},
		addQuery(state, action: PayloadAction<IQuery>) {
			const existingQuery = state.savedQueries.find((query) => query.id === action.payload.id);
			if (!existingQuery) {
				state.savedQueries = [action.payload, ...state.savedQueries];
			}
		},
		deleteQuery(state, action: PayloadAction<string>) {
			state.savedQueries = state.savedQueries.filter((query) => query.id !== action.payload);
			if (state.selectedQuery && state.selectedQuery.id === action.payload) {
				state.selectedQuery = null;
			}
		},
		setSelectedQuery(state, action: PayloadAction<IQuery | null>) {
			state.selectedQuery = action.payload;
		},
		setNewQuery(state, action: PayloadAction<IQuery>) {
			state.newQuery = action.payload;
		},
		setLoadingState(state, action: PayloadAction<boolean>) {
			state.loadingState = action.payload;
		},
		setStudyType(state, action: PayloadAction<StudyType>) {
			state.studyType = action.payload;
		},
		setConversationId(state, action: PayloadAction<string>) {
			state.conversationId = action.payload;
		},
		setSelectedQueriesConfig(state, action: PayloadAction<QueriesConfigType>) {
			state.selectedQueriesConfig = action.payload;
		},
		setIsCompDisabled(state, action: PayloadAction<boolean>) {
			state.isCompDisabled = action.payload;
		},
		// You can add more reducers here if needed
	},
});

export const {
	setAllQueries,
	addQuery,
	deleteQuery,
	setSelectedQuery,
	setNewQuery,
	setLoadingState,
	setStudyType,
	setConversationId,
	setSelectedQueriesConfig,
	setIsCompDisabled,
} = queriesSlice.actions;

export default queriesSlice.reducer;
