// src/components/ErrorBoundary/index.tsx
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './fallback';

interface ErrorBoundaryProps {
    children: React.ReactNode;
    resetKeys?: any[]; // Add this prop to handle reset keys
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, resetKeys }) => {
    return (
        <ReactErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // Reset the state of your application
            }}
            resetKeys={resetKeys} // Pass the reset keys
        >
            {children}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;
