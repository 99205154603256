import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManageStudyState } from "src/pages/ManageStudies/models/interfaces";

const initialState: ManageStudyState = {
	access_Type: "",
	isEditable: false,
	manageStudyDetails: {},
	insights_id: null,
};

const manageStudySlice = createSlice({
	name: "manageStudy",
	initialState,
	reducers: {
		setManageStudyDetails(state, action: PayloadAction<any>) {
			state.manageStudyDetails = action.payload;
		},
		setInsightsId(state, action: PayloadAction<string>) {
			state.insights_id = action.payload;
		},
		setNewTranscriptData(state, action: PayloadAction<any[] | null | undefined>) {
			if (!state.manageStudyDetails.processed_elements) {
				state.manageStudyDetails.processed_elements = { transcripts: [] };
			}
			state.manageStudyDetails.processed_elements.transcripts = Array.isArray(action.payload) ? action.payload : [];
		},
		setNewReportData(state, action: PayloadAction<any[] | null | undefined>) {
			if (!state.manageStudyDetails.processed_elements) {
				state.manageStudyDetails.processed_elements = { reports: [] };
			}
			state.manageStudyDetails.processed_elements.reports = Array.isArray(action.payload) ? action.payload : [];
		},
		setNewDiscussionGuideData(state, action: PayloadAction<any>) {
			if (action.payload && action.payload.length > 0) {
				if (!state.manageStudyDetails.processed_elements) {
					state.manageStudyDetails.processed_elements = { discussion_guide: [] };
				}
				state.manageStudyDetails.processed_elements.discussion_guide = action.payload;
			} else {
				state.manageStudyDetails.processed_elements.discussion_guide = [];
			}
		},
		setRemoveDiscussionGuideData(state) {
			state.manageStudyDetails.processed_elements.discussion_guide = [];
		},
		setAccessType(state, action: PayloadAction<{ access_type: string }>) {
			const { access_type } = action.payload;
			state.access_Type = access_type;
		},
		setIsEditable(state, action: PayloadAction<{ isEditable: boolean }>) {
			const { isEditable } = action.payload;
			state.isEditable = isEditable;
		},
	},
});

export const {
	setAccessType,
	setInsightsId,
	setIsEditable,
	setManageStudyDetails,
	setNewDiscussionGuideData,
	setNewReportData,
	setNewTranscriptData,
	setRemoveDiscussionGuideData,
} = manageStudySlice.actions;

export default manageStudySlice.reducer;
