import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Form, Input, Select, UploadOutlined, DownloadOutlined, Space } from "src/assets/imports/antdComponents";
import { RootState } from "src/store";
import "./style.css";

const modalStyles = {
	header: { boxShadow: "0px 4px 16px 0px #0000001a", height: "4.5rem", padding: "1.25rem 1.5rem", margin: 0 },
	body: { padding: "1.5rem" },
	mask: { backdropFilter: "blur(5px)" },
	footer: { padding: "1rem 1.5rem", margin: 0 },
	content: { padding: 0, margin: 0 },
};

const AddUserModal = (props) => {
	const { disabledFields, form, handleClose, handleSave, isModalVisible, setUserDetails, tableData, userDetails } = props;
	const [isSaveDisabled, setIsSaveDisabled] = useState({ email: true, empId: true, name: true, profile: true });
	const [isNotChanged, setIsNotChanged] = useState(true);
	const adminDropdownMaster = useSelector((state: RootState) => state.userManagementSlice.adminDropDownMaster);
	const options = adminDropdownMaster.map((item: string) => ({
		label: item,
		value: item,
	}));

	useEffect(() => {
		if (disabledFields) {
			setIsSaveDisabled({ email: false, empId: false, name: false, profile: true });
		} else {
			setIsSaveDisabled({ email: false, empId: false, name: false, profile: false });
		}
	}, [disabledFields]);

	// Sync the userDetails with form fields when userDetails changes
	useEffect(() => {
		if (userDetails) {
			form.setFieldsValue({
				email: userDetails.email,
				name: userDetails.name,
				employeeId: userDetails.emp_id,
				profile: userDetails.profiles,
			});
		}
	}, [userDetails, form]);

	// Function to handle changes in form inputs
	const handleChange = (value, fieldName) => {
		// const processedValue = fieldName === "emp_id" ? value.replace(/\\\\\\/g, "\\") : value;

		setUserDetails((prevDetails) => ({
			...prevDetails,
			[fieldName]: value,
		}));
		setIsNotChanged(false);
	};

	// Custom validation functions
	const validateEmail = (_, value) => {
		if (value) {
			const invalidSpecialCharRegex = /[^a-zA-Z0-9@.]/;
			const validDomain = value.endsWith("@zs.com") || value.endsWith("@zsservices.com");

			if (invalidSpecialCharRegex.test(value)) {
				setIsSaveDisabled((prev) => ({ ...prev, email: true }));
				return Promise.reject("Allowed special characters: '.', '@'.");
			}

			if (!validDomain) {
				setIsSaveDisabled((prev) => ({ ...prev, email: true }));
				return Promise.reject("Please enter a valid email address and ensure it ends with zs.com or zsservices.com");
			}

			const emailLowerCase = value.toLowerCase();
			const emailExists = tableData.some((entry) => entry.collaborator_email.toLowerCase() === emailLowerCase);

			if (emailExists) {
				setIsSaveDisabled((prev) => ({ ...prev, email: true }));
				return Promise.reject("User with the email already exists.");
			}
		}
		setIsSaveDisabled((prev) => ({ ...prev, email: false }));
		return Promise.resolve();
	};

	const validateName = (_, value) => {
		if (value) {
			const nameRegex = /^[a-zA-Z0-9\s]+$/;
			if (!nameRegex.test(value)) {
				setIsSaveDisabled((prev) => ({ ...prev, name: true }));
				return Promise.reject("Only alphanumeric characters are allowed");
			}

			const nameLowerCase = value.toLowerCase();
			const nameExists = tableData.some((entry) => entry.collaborator_name.toLowerCase() === nameLowerCase);

			if (nameExists) {
				setIsSaveDisabled((prev) => ({ ...prev, name: true }));
				return Promise.reject("User with the name already exists.");
			}
		}
		setIsSaveDisabled((prev) => ({ ...prev, name: false }));
		return Promise.resolve();
	};

	const validateEmpID = (_, value) => {
		if (value) {
			const empIdRegex = /^[a-zA-Z0-9\s-]+$/;
			if (!empIdRegex.test(value)) {
				setIsSaveDisabled((prev) => ({ ...prev, empId: true }));
				return Promise.reject("Allowed special character: -");
			}

			const idExists = tableData.some((entry) => {
				const collaboratorIdParts = entry.collaborator_id.split("\\");
				return collaboratorIdParts.length > 1 && collaboratorIdParts[1].toLowerCase() === value.toLowerCase();
			});

			if (idExists) {
				setIsSaveDisabled((prev) => ({ ...prev, empId: true }));
				return Promise.reject("User with the employee Id already exists.");
			}
		}
		setIsSaveDisabled((prev) => ({ ...prev, empId: false }));
		return Promise.resolve();
	};

	const validateProfiles = (_, value) => {
		if (value.length === 0) {
			setIsSaveDisabled((prev) => ({ ...prev, profile: true }));
			return Promise.reject("Please select a user profile");
		}
		setIsSaveDisabled((prev) => ({ ...prev, profile: false }));
		return Promise.resolve();
	};

	return (
		<Modal
			title={disabledFields ? "Update User" : "Add User"}
			open={isModalVisible}
			onCancel={handleClose}
			className="add-user-modal"
			styles={modalStyles}
			width={820}
			footer={
				<div className="flex justify-end">
					{/* <div className="flex space-x-4">
						<Button icon={<UploadOutlined />} className="upload-template-btn">
							Upload User Details Template
						</Button>
						<Button icon={<DownloadOutlined />} className="download-template-btn">
							Download User Details Template
						</Button>
					</div> */}
					<div className="flex space-x-4">
						<Button onClick={handleClose}>Cancel</Button>
						<Button type="primary" onClick={handleSave} disabled={Object.values(isSaveDisabled).includes(true) || isNotChanged}>
							{disabledFields ? "Update" : "Save"}
						</Button>
					</div>
				</div>
			}>
			<Form form={form} layout="vertical">
				<div className="grid grid-cols-1 gap-4">
					<Form.Item label="E-mail ID" name="email" rules={disabledFields ? [] : [{ required: true, message: "Email Id is required" }, { validator: validateEmail }]}>
						<Input
							autoComplete="off"
							disabled={disabledFields}
							placeholder="Enter Email ID. E.g. John.Doe@zs.com"
							value={userDetails.email}
							onChange={(e) => handleChange(e.target.value, "email")}
						/>
					</Form.Item>
				</div>
				<div className="grid grid-cols-2 gap-4">
					<Form.Item label="Name" name="name" rules={disabledFields ? [] : [{ required: true, message: "Name is required" }, { validator: validateName }]}>
						<Input
							autoComplete="off"
							disabled={disabledFields}
							placeholder="Enter Name. E.g. John Doe"
							value={userDetails.name}
							onChange={(e) => handleChange(e.target.value, "name")}
						/>
					</Form.Item>
					<Form.Item
						label="Employee ID"
						name="employeeId"
						rules={disabledFields ? [] : [{ required: true, message: "Employee Id is required" }, { validator: validateEmpID }]}>
						<Input
							autoComplete="off"
							disabled={disabledFields}
							placeholder="Enter ZS Employee ID. E.g. jd101010 or c-jd10101010"
							value={userDetails.emp_id}
							onChange={(e) => handleChange(e.target.value, "emp_id")}
						/>
					</Form.Item>
				</div>

				<Form.Item label="Select Profile" name="profile" rules={[{ validator: validateProfiles }]}>
					<Select
						mode="multiple"
						style={{ width: "100%" }}
						placeholder="Select a profile from the list"
						value={userDetails.profiles}
						onChange={(value) => handleChange(value, "profiles")}
						options={options}
						optionRender={(option) => (
							<Space>
								<span role="img" aria-label={option.data.label}>
									{option.data.label}
								</span>
							</Space>
						)}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddUserModal;
