// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subHeader-nav {
	height: 3.75rem;
	border-bottom: 1px solid #5e80b726;
	background-color: #fafafa;
}

.subHeader-tag {
	background: #4bfbc01a;
	font-weight: 500;
	line-height: 22px;
	padding: 0 1rem;
	cursor: pointer;
	border: 1px solid #4cbb9640;
	opacity: 100%;
	color: #1e489c;
}

.subHeader-tag.active {
	font-weight: 600;
	border: 2px solid #4cbb96;
	opacity: 100%;
	transition: all 0.01s ease-in-out;
}

.study-tag {
	background: #ebf9f4;
	border: 0px;
	font-size: x-small;
	font-weight: 500;
	color: #112e65;
	padding-left: 1rem;
	padding-right: 1rem;
	border: 1px solid #4cbb9640;
}
`, "",{"version":3,"sources":["webpack://./src/components/SubHeader/style.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,kCAAkC;CAClC,yBAAyB;AAC1B;;AAEA;CACC,qBAAqB;CACrB,gBAAgB;CAChB,iBAAiB;CACjB,eAAe;CACf,eAAe;CACf,2BAA2B;CAC3B,aAAa;CACb,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,yBAAyB;CACzB,aAAa;CACb,iCAAiC;AAClC;;AAEA;CACC,mBAAmB;CACnB,WAAW;CACX,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;CACd,kBAAkB;CAClB,mBAAmB;CACnB,2BAA2B;AAC5B","sourcesContent":[".subHeader-nav {\n\theight: 3.75rem;\n\tborder-bottom: 1px solid #5e80b726;\n\tbackground-color: #fafafa;\n}\n\n.subHeader-tag {\n\tbackground: #4bfbc01a;\n\tfont-weight: 500;\n\tline-height: 22px;\n\tpadding: 0 1rem;\n\tcursor: pointer;\n\tborder: 1px solid #4cbb9640;\n\topacity: 100%;\n\tcolor: #1e489c;\n}\n\n.subHeader-tag.active {\n\tfont-weight: 600;\n\tborder: 2px solid #4cbb96;\n\topacity: 100%;\n\ttransition: all 0.01s ease-in-out;\n}\n\n.study-tag {\n\tbackground: #ebf9f4;\n\tborder: 0px;\n\tfont-size: x-small;\n\tfont-weight: 500;\n\tcolor: #112e65;\n\tpadding-left: 1rem;\n\tpadding-right: 1rem;\n\tborder: 1px solid #4cbb9640;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
