import { apiService } from "../../../services";
import { IAddStudyData, IDeleteFilePayload, IFileDownloadPayload } from "../models/interfaces";
import { generateFileName } from "../transformer";

export const getAllStudies = async () => {
	return await apiService.get("entities/basic");
};

export const getDropdownValues = async () => {
	return await apiService.get("masters/dropdown");
};

export const getMetaDetails = async () => {
	return await apiService.get("masters/metadata");
};

export const addNewStudy = async (values: IAddStudyData) => {
	return await apiService.post("entities/", values);
};

export const editStudy = async (values: IAddStudyData) => {
	return await apiService.put("entities/edit", values);
};

export const deleteStudy = async (study_id: string) => {
	return await apiService.delete("entities/", { study_id });
};

export const getFilesDetails = async (study_id: string) => {
	return await apiService.get("files/", { study_id });
};

export const deleteFiles = async (values: IDeleteFilePayload) => {
	return await apiService.post("files/", values);
};

export const getCollaborators = async (search_string: string) => {
	return await apiService.get("entities/collaborator", { search_string });
};

export const addCollaborators = async (values) => {
	return await apiService.post("entities/addCollaborator", values);
};

export const downloadFile = async (values: IFileDownloadPayload) => {
	const { file_name, file_type } = values;
	const fileName = generateFileName(file_type, file_name);
	try {
		const blob = await apiService.downloadFile("files/download", values);
		if (blob) {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = fileName; // Use the original file name for download
			document.body.appendChild(a); // Append the anchor to the body
			a.click(); // Trigger the download
			document.body.removeChild(a); // Remove the anchor from the body
			window.URL.revokeObjectURL(url); // Clean up the object URL
		}
	} catch (err) {
		console.error("Error downloading file: ", err);
		throw err;
	}
};
