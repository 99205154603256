type FileTypeString = "Transcript" | "Report" | "Discussion Guide";
type UserAccessTypeString = "Read Access" | "Full Access";
type studyTypeString = "Market Research" | "Reports";
type statusLogString = "success" | "failed" | "queued" | "processing" | "upload_failed" | "uploading";

export enum userAccessType {
	view = "Read Access",
	edit = "Full Access",
}

export enum roleAccessType {
	Admin = "Admin",
}

export enum storyType {
	type1 = "Market Research",
	type2 = "Reports",
}

export enum confirmationTypes {
	type1 = "closeIcon",
	type2 = "switch",
	type3 = "sideBar",
}

export enum confirmationStatusType {
	success = "success",
	failure = "failure",
	warning = "warning",
}

export enum fileStatus {
	uploading = "uploading",
	queued = "queued",
	processing = "processing",
	success = "success",
	failed = "failed",
	upload_failed = "upload_failed",
}

export enum fileTypes {
	discussion_guide = "Discussion Guide",
	transcript = "Transcript",
	report = "Report",
	metadata = "Metadata",
	all = "All",
	allInsights = "All Insights",
	insightsQuestion = "Insights Question",
}

export enum downloadFormats {
	discussion_guide = "docx",
	transcript = "docx",
	report = "docx",
	metadata = "xlsx",
	all = "zip",
}

export interface ISelectedStudy {
	study_id: string | null;
	study_name: string | null;
	study_tags?: string[] | null;
	study_collaborators?: ICollaboratorData[] | null;
	insights_id?: string | null;
	access_type: UserAccessTypeString;
	created_by?: IAuthorData | null;
}

export interface IAddStudyData {
	study_id?: string;
	study_name: string;
	geography_id?: string;
	start_date?: string;
	end_date?: string;
	description: string;
	project_code?: string;
	custom_tags?: string[];
	study_type_id: string;
}

export interface IUserData {
	full_name: string;
	user_id: string;
	email: string;
}

export interface IVersionHistory {
	created_dt: string;
	created_by: IUserData;
	modified_dt: string;
	modified_by: IUserData;
}

export interface ICollaboratorData {
	collaborator_id: string;
	collaborator_email: string;
	collaborator_name: string;
	user_access_type: UserAccessTypeString;
}

export interface IStudyData {
	study_id: string;
	study_name: string;
	study_type: studyTypeString;
	description: string;
	start_dt: string;
	end_dt: string;
	geography: string;
	user_access_type: UserAccessTypeString;
	collaborators: ICollaboratorData[];
	custom_tags: string[] | null;
	count_of_transcript: number;
	count_of_report: number;
	version_history: IVersionHistory;
	insights_available?: boolean;
	active_insights_id: string;
}

export interface IDropdownItem {
	label: string | studyTypeString;
	value: string;
}

export interface IDropdownMaster {
	study_type: IDropdownItem[];
	geography: IDropdownItem[];
	custom_tags: IDropdownItem[];
}

export interface ICollabCardProps {
	payload: IStudyData;
	handleDeleteStudy?: (study_id: string) => void;
	activeStudyId?: string;
	isSelected: boolean;
	onSelect: () => void;
	onEditStudy: () => void;
}

export interface IFileTableProps {
	data: Record<string, any>[];
	handleDeleteFile?: (fileId: string, fileType: string, secFileId: string) => void;
}

export interface ICustomFormProps {
	title?: string;
	form: any;
	onFinish?: (values: any) => void;
	formConfig: Array<any>;
}

export interface ICustModalProps {
	isModalOpen: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	studyData?: IAddStudyData;
	payload?: ICollaboratorData[];
	studyId?: string;
}

export interface IResponse {
	data?: any;
	error?: any;
	message?: string | null;
}

export interface IFilesUploadProps {
	studyType: string;
	studyId: string;
}

export interface ITableProps {
	id: string;
	uploaded_files: string;
	uploaded_by: string;
	uploaded_date: string;
	status: string;
	type: string;
	status_log: statusLogString;
	file_id: string;
	[key: string]: string;
}

export interface IDeleteFilePayload {
	study_id: string;
	document_type: string;
	document_id: string;
	file_id: string;
}

export interface IFileDownloadPayload {
	file_name: string;
	file_type: string;
	study_id: string;
	file_id: string;
	doc_download_format: string;
	study_type_name?: string;
}

export interface IReportAvailable {
	home: boolean;
	manage_study: boolean;
	add_study: boolean;
	ask_kural: boolean;
	[key: string]: boolean;
}

export interface IConfig {
	is_report_available: IReportAvailable;
}

export interface IMetaDetails {
	config: IConfig;
	id: string;
	name: string;
	firstName: string;
	lastName: string;
	isAdmin?: boolean;
	roles?: string[];
	profiles?: string[];
	idm_application_name?: string;
	user_email: string;
}

export interface InsightDetails {
	study_id: string;
	[key: string]: any;
}

export interface DeepDiveData {
	collaborators: any[];
	insight_details: InsightDetails;
	study_name: string;
	study_tags: any[];
	user_access_type: string;
}

export interface IConfirmationObj {
	status: string;
	editType: string;
	visible: boolean;
	name: string;
	redirectFrom: string;
}

export enum ReportAvailabilityKey {
	HOME = "home",
	MANAGE_STUDY = "manage_study",
	ADD_STUDY = "add_study",
	ASK_KURAL = "ask_kural",
}

export enum CognitiveAvailabilityKey {
	HOME = "home",
	MANAGE_STUDY = "manage_study",
	ADD_STUDY = "add_study",
	ASK_KURAL = "ask_kural",
}

export interface IReportPreviewResponse {
	url: string;
}

export interface ICompletionData {
	upload_data?: {
		upload_id: string;
		parts: IPart[];
	};
	study_type_name: string;
	file_id: string;
	study_id: string;
	file_type: string;
	file_name: string;
	total_chunks: number;
	is_error: boolean;
}

interface IPart {
	PartNumber: number;
	ETag: string;
}

interface IPresignedUrlsInfo {
	part_number: number;
	url: string;
}

export interface IPresignedUrlsResponse {
	file_id: string;
	upload_id: string;
	file_name: string;
	presigned_urls_info: IPresignedUrlsInfo[];
}

export interface IAuthorData {
	email: string;
	full_name: string;
	user_id: string;
	_id?: string;
	user_access_type?: UserAccessTypeString;
}

export interface ICollaboratorsTableProps {
	authorUser: IAuthorData;
	fullAccessUsers: ICollaboratorData[];
	isModalOpen: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	studyId?: string;
}
