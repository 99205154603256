import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConfirmationObj } from "src/pages/Studies/models/interfaces";

const unsavedChangesSlice = createSlice({
	name: "unsavedChanges",
	initialState: {
		hasUnsavedChanges: false,
		confirmationData: { redirectFrom: "", status: "", editType: "", visible: false, name: "" },
	},
	reducers: {
		setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
			state.hasUnsavedChanges = action.payload;
		},
		setConfirmationData: (state, action: PayloadAction<IConfirmationObj>) => {
			state.confirmationData = action.payload;
		},
	},
});

export const { setConfirmationData, setUnsavedChanges } = unsavedChangesSlice.actions;

export default unsavedChangesSlice.reducer;
