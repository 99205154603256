// src/config/RoutesConfig.tsx
import React, { Suspense } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { menuItems, adminMenuItems } from "./menuConfig";
import Loading from "../components/LoadingComp";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { roleAccessType } from "src/pages/Studies/models/interfaces";
import { Button, Result } from "src/assets/imports/antdComponents";

const RoutesConfig: React.FC = () => {
	const userProfile = useSelector((state: RootState) => state.studiesSlice.userProfile);
	const menu = userProfile === roleAccessType.Admin ? adminMenuItems : menuItems;
	const navigate = useNavigate();
	return (
		<Suspense fallback={<Loading />}>
			<Routes>
				<Route path="/" element={<Navigate to={menu[0].path} />} />
				{menu.map((item) => (
					<Route key={item.key} path={item.path} element={<item.component />} />
				))}
				<Route
					path="*"
					element={
						<Result
							status="404"
							title=""
							subTitle="Sorry, the page you visited does not exist."
							extra={
								<Button onClick={() => navigate("/")} type="primary">
									Back Home
								</Button>
							}
						/>
					}
				/>
			</Routes>
		</Suspense>
	);
};

export default RoutesConfig;
