import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuery } from "../models/interfaces";

interface QueriesState {
	savedQueries: IQuery[];
	selectedQuery: IQuery | null;
	newQuery: IQuery | null;
	loadingState: boolean;
}

const initialState: QueriesState = {
	savedQueries: [],
	selectedQuery: null,
	newQuery: null,
	loadingState: false,
};

const queriesSlice = createSlice({
	name: "queries",
	initialState,
	reducers: {
		setAllQueries(state, action: PayloadAction<IQuery[]>) {
			state.savedQueries = action.payload;
		},
		addQuery(state, action: PayloadAction<IQuery>) {
			const existingQuery = state.savedQueries.find((query) => query.id === action.payload.id);
			if (!existingQuery) {
				state.savedQueries = [action.payload, ...state.savedQueries];
			}
		},
		deleteQuery(state, action: PayloadAction<string>) {
			state.savedQueries = state.savedQueries.filter((query) => query.id !== action.payload);
			if (state.selectedQuery && state.selectedQuery.id === action.payload) {
				state.selectedQuery = null;
			}
		},
		setSelectedQuery(state, action: PayloadAction<IQuery | null>) {
			state.selectedQuery = action.payload;
		},
		setNewQuery(state, action: PayloadAction<IQuery>) {
			state.newQuery = action.payload;
		},
		setLoadingState(state, action: PayloadAction<boolean>) {
			state.loadingState = action.payload;
		},
		// You can add more reducers here if needed
	},
});

export const { setAllQueries, addQuery, deleteQuery, setSelectedQuery, setNewQuery, setLoadingState } = queriesSlice.actions;

export default queriesSlice.reducer;
