// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-management .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
	background: #dde6f541 !important;
}

.profile-tag {
	background: #5e80b71a;
	font-size: x-small;
	font-weight: 400;
	color: #112e65;
	padding-left: 1rem;
	padding-right: 1rem;
	border: 1px solid #5e80b740;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/components/UserTable/style.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;AACjC;;AAEA;CACC,qBAAqB;CACrB,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;CACd,kBAAkB;CAClB,mBAAmB;CACnB,2BAA2B;AAC5B","sourcesContent":[".user-management .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {\n\tbackground: #dde6f541 !important;\n}\n\n.profile-tag {\n\tbackground: #5e80b71a;\n\tfont-size: x-small;\n\tfont-weight: 400;\n\tcolor: #112e65;\n\tpadding-left: 1rem;\n\tpadding-right: 1rem;\n\tborder: 1px solid #5e80b740;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
